export const USER_TITLES = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Prof'];

export const NATIONALITY = ['Saudi', 'Non-Saudi'].map((item) => ({
  label: item,
  value: item,
}));

export const GENDER_LIST = ['Male', 'Female'].map((item) => ({
  label: item,
  value: item,
}));

export const EDUCATION_LEVELS = [
  'PhD',
  'Masters',
  'Bachelor',
  'Diploma',
  // 'Student',
  // 'No Degree',
].map((item) => ({
  label: item,
  value: item,
}));

export const WORK_SECTORS = [
  'Government',
  'Semi-government',
  'Private sector',
  'Non-profit',
  'Academia',
].map((item) => ({
  label: item,
  value: item,
}));

export const ENGLISH_PROFICIENCY = [
  'Beginner',
  'Intermediate',
  'Professional',
].map((item) => ({
  label: item,
  value: item,
}));

export const COUNTRIES = [
  {
    id: 1,
    country: 'Afghanistan',
    capital: null,
    country_code: 'AF',
    nationality: null,
    dial_code: '93',
  },
  {
    id: 2,
    country: 'Aland Islands',
    capital: null,
    country_code: 'AX',
    nationality: null,
    dial_code: '358',
  },
  {
    id: 3,
    country: 'Albania',
    capital: null,
    country_code: 'AL',
    nationality: null,
    dial_code: '355',
  },
  {
    id: 4,
    country: 'Algeria',
    capital: null,
    country_code: 'DZ',
    nationality: null,
    dial_code: '213',
  },
  {
    id: 5,
    country: 'American Samoa',
    capital: null,
    country_code: 'AS',
    nationality: null,
    dial_code: '684',
  },
  {
    id: 6,
    country: 'Andorra',
    capital: null,
    country_code: 'AD',
    nationality: null,
    dial_code: '376',
  },
  {
    id: 7,
    country: 'Angola',
    capital: null,
    country_code: 'AO',
    nationality: null,
    dial_code: '244',
  },
  {
    id: 8,
    country: 'Anguilla',
    capital: null,
    country_code: 'AI',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 9,
    country: 'Antarctica',
    capital: null,
    country_code: 'AQ',
    nationality: null,
    dial_code: '672',
  },
  {
    id: 10,
    country: 'Antigua And Barbuda',
    capital: null,
    country_code: 'AG',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 11,
    country: 'Argentina',
    capital: null,
    country_code: 'AR',
    nationality: null,
    dial_code: '54',
  },
  {
    id: 12,
    country: 'Armenia',
    capital: null,
    country_code: 'AM',
    nationality: null,
    dial_code: '374',
  },
  {
    id: 13,
    country: 'Aruba',
    capital: null,
    country_code: 'AW',
    nationality: null,
    dial_code: '297',
  },
  {
    id: 14,
    country: 'Australia',
    capital: null,
    country_code: 'AU',
    nationality: null,
    dial_code: '61',
  },
  {
    id: 15,
    country: 'Austria',
    capital: null,
    country_code: 'AT',
    nationality: null,
    dial_code: '43',
  },
  {
    id: 16,
    country: 'Azerbaijan',
    capital: null,
    country_code: 'AZ',
    nationality: null,
    dial_code: '994',
  },
  {
    id: 17,
    country: 'Bahamas',
    capital: null,
    country_code: 'BS',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 18,
    country: 'Bahrain',
    capital: 'Manama',
    country_code: 'BH',
    nationality: null,
    dial_code: '973',
  },
  {
    id: 19,
    country: 'Bangladesh',
    capital: null,
    country_code: 'BD',
    nationality: null,
    dial_code: '880',
  },
  {
    id: 20,
    country: 'Barbados',
    capital: null,
    country_code: 'BB',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 21,
    country: 'Belarus',
    capital: null,
    country_code: 'BY',
    nationality: null,
    dial_code: '375',
  },
  {
    id: 22,
    country: 'Belgium',
    capital: null,
    country_code: 'BE',
    nationality: null,
    dial_code: '32',
  },
  {
    id: 23,
    country: 'Belize',
    capital: null,
    country_code: 'BZ',
    nationality: null,
    dial_code: '501',
  },
  {
    id: 24,
    country: 'Benin',
    capital: null,
    country_code: 'BJ',
    nationality: null,
    dial_code: '229',
  },
  {
    id: 25,
    country: 'Bermuda',
    capital: null,
    country_code: 'BM',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 26,
    country: 'Bhutan',
    capital: null,
    country_code: 'BT',
    nationality: null,
    dial_code: '975',
  },
  {
    id: 27,
    country: 'Bolivia',
    capital: null,
    country_code: 'BO',
    nationality: null,
    dial_code: '591',
  },
  {
    id: 28,
    country: 'Bosnia And Herzegovina',
    capital: null,
    country_code: 'BA',
    nationality: null,
    dial_code: '387',
  },
  {
    id: 29,
    country: 'Botswana',
    capital: null,
    country_code: 'BW',
    nationality: null,
    dial_code: '267',
  },
  {
    id: 30,
    country: 'Bouvet Island',
    capital: null,
    country_code: 'BV',
    nationality: null,
    dial_code: '47',
  },
  {
    id: 31,
    country: 'Brazil',
    capital: null,
    country_code: 'BR',
    nationality: null,
    dial_code: '55',
  },
  {
    id: 32,
    country: 'British Indian Ocean Territory',
    capital: null,
    country_code: 'IO',
    nationality: null,
    dial_code: '246',
  },
  {
    id: 33,
    country: 'Brunei Darussalam',
    capital: null,
    country_code: 'BN',
    nationality: null,
    dial_code: '673',
  },
  {
    id: 34,
    country: 'Bulgaria',
    capital: null,
    country_code: 'BG',
    nationality: null,
    dial_code: '359',
  },
  {
    id: 35,
    country: 'Burkina Faso',
    capital: null,
    country_code: 'BF',
    nationality: null,
    dial_code: '226',
  },
  {
    id: 36,
    country: 'Burundi',
    capital: null,
    country_code: 'BI',
    nationality: null,
    dial_code: '257',
  },
  {
    id: 37,
    country: 'Cambodia',
    capital: null,
    country_code: 'KH',
    nationality: null,
    dial_code: '855',
  },
  {
    id: 38,
    country: 'Cameroon',
    capital: null,
    country_code: 'CM',
    nationality: null,
    dial_code: '237',
  },
  {
    id: 39,
    country: 'Canada',
    capital: null,
    country_code: 'CA',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 40,
    country: 'Cape Verde',
    capital: null,
    country_code: 'CV',
    nationality: null,
    dial_code: '238',
  },
  {
    id: 41,
    country: 'Cayman Islands',
    capital: null,
    country_code: 'KY',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 42,
    country: 'Central African Republic',
    capital: null,
    country_code: 'CF',
    nationality: null,
    dial_code: '236',
  },
  {
    id: 43,
    country: 'Chad',
    capital: null,
    country_code: 'TD',
    nationality: null,
    dial_code: '235',
  },
  {
    id: 44,
    country: 'Chile',
    capital: null,
    country_code: 'CL',
    nationality: null,
    dial_code: '56',
  },
  {
    id: 45,
    country: 'China',
    capital: null,
    country_code: 'CN',
    nationality: null,
    dial_code: '86',
  },
  {
    id: 46,
    country: 'Christmas Island',
    capital: null,
    country_code: 'CX',
    nationality: null,
    dial_code: '618',
  },
  {
    id: 47,
    country: 'Cocos (Keeling) Islands',
    capital: null,
    country_code: 'CC',
    nationality: null,
    dial_code: '61',
  },
  {
    id: 48,
    country: 'Colombia',
    capital: null,
    country_code: 'CO',
    nationality: null,
    dial_code: '57',
  },
  {
    id: 49,
    country: 'Comoros',
    capital: null,
    country_code: 'KM',
    nationality: null,
    dial_code: '269',
  },
  {
    id: 50,
    country: 'Congo',
    capital: null,
    country_code: 'CG',
    nationality: null,
    dial_code: '242',
  },
  {
    id: 51,
    country: 'Congo, Democratic Republic',
    capital: null,
    country_code: 'CD',
    nationality: null,
    dial_code: '243',
  },
  {
    id: 52,
    country: 'Cook Islands',
    capital: null,
    country_code: 'CK',
    nationality: null,
    dial_code: '682',
  },
  {
    id: 53,
    country: 'Costa Rica',
    capital: null,
    country_code: 'CR',
    nationality: null,
    dial_code: '506',
  },
  {
    id: 54,
    country: "Cote D'Ivoire",
    capital: null,
    country_code: 'CI',
    nationality: null,
    dial_code: '225',
  },
  {
    id: 55,
    country: 'Croatia',
    capital: null,
    country_code: 'HR',
    nationality: null,
    dial_code: '385',
  },
  {
    id: 56,
    country: 'Cuba',
    capital: null,
    country_code: 'CU',
    nationality: null,
    dial_code: '53',
  },
  // {
  //   id: 57,
  //   country: 'Curacao',
  //   capital: null,
  //   country_code: 'CW',
  //   nationality: null,
  //   dial_code: null,
  // },
  {
    id: 58,
    country: 'Cyprus',
    capital: null,
    country_code: 'CY',
    nationality: null,
    dial_code: '357',
  },
  {
    id: 59,
    country: 'Czech Republic',
    capital: null,
    country_code: 'CZ',
    nationality: null,
    dial_code: '420',
  },
  {
    id: 60,
    country: 'Denmark',
    capital: null,
    country_code: 'DK',
    nationality: null,
    dial_code: '45',
  },
  {
    id: 61,
    country: 'Djibouti',
    capital: null,
    country_code: 'DJ',
    nationality: null,
    dial_code: '253',
  },
  {
    id: 62,
    country: 'Dominica',
    capital: null,
    country_code: 'DM',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 63,
    country: 'Dominican Republic',
    capital: null,
    country_code: 'DO',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 64,
    country: 'Ecuador',
    capital: null,
    country_code: 'EC',
    nationality: null,
    dial_code: '593',
  },
  {
    id: 65,
    country: 'Egypt',
    capital: null,
    country_code: 'EG',
    nationality: null,
    dial_code: '20',
  },
  {
    id: 66,
    country: 'El Salvador',
    capital: null,
    country_code: 'SV',
    nationality: null,
    dial_code: '503',
  },
  {
    id: 67,
    country: 'Equatorial Guinea',
    capital: null,
    country_code: 'GQ',
    nationality: null,
    dial_code: '240',
  },
  {
    id: 68,
    country: 'Eritrea',
    capital: null,
    country_code: 'ER',
    nationality: null,
    dial_code: '291',
  },
  {
    id: 69,
    country: 'Estonia',
    capital: null,
    country_code: 'EE',
    nationality: null,
    dial_code: '372',
  },
  {
    id: 70,
    country: 'Ethiopia',
    capital: null,
    country_code: 'ET',
    nationality: null,
    dial_code: '251',
  },
  {
    id: 71,
    country: 'Falkland Islands (Malvinas)',
    capital: null,
    country_code: 'FK',
    nationality: null,
    dial_code: '500',
  },
  {
    id: 72,
    country: 'Faroe Islands',
    capital: null,
    country_code: 'FO',
    nationality: null,
    dial_code: '298',
  },
  {
    id: 73,
    country: 'Fiji',
    capital: null,
    country_code: 'FJ',
    nationality: null,
    dial_code: '679',
  },
  {
    id: 74,
    country: 'Finland',
    capital: null,
    country_code: 'FI',
    nationality: null,
    dial_code: '358',
  },
  {
    id: 75,
    country: 'France',
    capital: null,
    country_code: 'FR',
    nationality: null,
    dial_code: '33',
  },
  {
    id: 76,
    country: 'French Guiana',
    capital: null,
    country_code: 'GF',
    nationality: null,
    dial_code: '594',
  },
  {
    id: 77,
    country: 'French Polynesia',
    capital: null,
    country_code: 'PF',
    nationality: null,
    dial_code: '689',
  },
  {
    id: 78,
    country: 'French Southern Territories',
    capital: null,
    country_code: 'TF',
    nationality: null,
    dial_code: '0',
  },
  {
    id: 79,
    country: 'Gabon',
    capital: null,
    country_code: 'GA',
    nationality: null,
    dial_code: '241',
  },
  {
    id: 80,
    country: 'Gambia',
    capital: null,
    country_code: 'GM',
    nationality: null,
    dial_code: '220',
  },
  {
    id: 81,
    country: 'Georgia',
    capital: null,
    country_code: 'GE',
    nationality: null,
    dial_code: '995',
  },
  {
    id: 82,
    country: 'Germany',
    capital: null,
    country_code: 'DE',
    nationality: null,
    dial_code: '49',
  },
  {
    id: 83,
    country: 'Ghana',
    capital: null,
    country_code: 'GH',
    nationality: null,
    dial_code: '233',
  },
  {
    id: 84,
    country: 'Gibraltar',
    capital: null,
    country_code: 'GI',
    nationality: null,
    dial_code: '350',
  },
  {
    id: 85,
    country: 'Greece',
    capital: null,
    country_code: 'GR',
    nationality: null,
    dial_code: '30',
  },
  {
    id: 86,
    country: 'Greenland',
    capital: null,
    country_code: 'GL',
    nationality: null,
    dial_code: '299',
  },
  {
    id: 87,
    country: 'Grenada',
    capital: null,
    country_code: 'GD',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 88,
    country: 'Guadeloupe',
    capital: null,
    country_code: 'GP',
    nationality: null,
    dial_code: '590',
  },
  {
    id: 89,
    country: 'Guam',
    capital: null,
    country_code: 'GU',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 90,
    country: 'Guatemala',
    capital: null,
    country_code: 'GT',
    nationality: null,
    dial_code: '502',
  },
  {
    id: 91,
    country: 'Guernsey',
    capital: null,
    country_code: 'GG',
    nationality: null,
    dial_code: '44',
  },
  {
    id: 92,
    country: 'Guinea',
    capital: null,
    country_code: 'GN',
    nationality: null,
    dial_code: '224',
  },
  {
    id: 93,
    country: 'Guinea-Bissau',
    capital: null,
    country_code: 'GW',
    nationality: null,
    dial_code: '245',
  },
  {
    id: 94,
    country: 'Guyana',
    capital: null,
    country_code: 'GY',
    nationality: null,
    dial_code: '592',
  },
  {
    id: 95,
    country: 'Haiti',
    capital: null,
    country_code: 'HT',
    nationality: null,
    dial_code: '509',
  },
  {
    id: 96,
    country: 'Heard Island & Mcdonald Islands',
    capital: null,
    country_code: 'HM',
    nationality: null,
    dial_code: '0',
  },
  {
    id: 97,
    country: 'Holy See (Vatican City State)',
    capital: null,
    country_code: 'VA',
    nationality: null,
    dial_code: '39',
  },
  {
    id: 98,
    country: 'Honduras',
    capital: null,
    country_code: 'HN',
    nationality: null,
    dial_code: '504',
  },
  {
    id: 99,
    country: 'Hong Kong',
    capital: null,
    country_code: 'HK',
    nationality: null,
    dial_code: '852',
  },
  {
    id: 100,
    country: 'Hungary',
    capital: null,
    country_code: 'HU',
    nationality: null,
    dial_code: '36',
  },
  {
    id: 101,
    country: 'Iceland',
    capital: null,
    country_code: 'IS',
    nationality: null,
    dial_code: '354',
  },
  {
    id: 102,
    country: 'India',
    capital: null,
    country_code: 'IN',
    nationality: null,
    dial_code: '91',
  },
  {
    id: 103,
    country: 'Indonesia',
    capital: null,
    country_code: 'ID',
    nationality: null,
    dial_code: '62',
  },
  {
    id: 104,
    country: 'Iran, Islamic Republic Of',
    capital: null,
    country_code: 'IR',
    nationality: null,
    dial_code: '98',
  },
  {
    id: 105,
    country: 'Iraq',
    capital: null,
    country_code: 'IQ',
    nationality: null,
    dial_code: '964',
  },
  {
    id: 106,
    country: 'Ireland',
    capital: null,
    country_code: 'IE',
    nationality: null,
    dial_code: '353',
  },
  {
    id: 107,
    country: 'Isle Of Man',
    capital: null,
    country_code: 'IM',
    nationality: null,
    dial_code: '44',
  },
  {
    id: 108,
    country: 'Israel',
    capital: null,
    country_code: 'IL',
    nationality: null,
    dial_code: '972',
  },
  {
    id: 109,
    country: 'Italy',
    capital: null,
    country_code: 'IT',
    nationality: null,
    dial_code: '39',
  },
  {
    id: 110,
    country: 'Jamaica',
    capital: null,
    country_code: 'JM',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 111,
    country: 'Japan',
    capital: null,
    country_code: 'JP',
    nationality: null,
    dial_code: '81',
  },
  {
    id: 112,
    country: 'Jersey',
    capital: null,
    country_code: 'JE',
    nationality: null,
    dial_code: '44',
  },
  {
    id: 113,
    country: 'Jordan',
    capital: null,
    country_code: 'JO',
    nationality: null,
    dial_code: '962',
  },
  {
    id: 114,
    country: 'Kazakhstan',
    capital: null,
    country_code: 'KZ',
    nationality: null,
    dial_code: '7',
  },
  {
    id: 115,
    country: 'Kenya',
    capital: null,
    country_code: 'KE',
    nationality: null,
    dial_code: '254',
  },
  {
    id: 116,
    country: 'Kiribati',
    capital: null,
    country_code: 'KI',
    nationality: null,
    dial_code: '686',
  },
  {
    id: 117,
    country: 'Korea',
    capital: null,
    country_code: 'KR',
    nationality: null,
    dial_code: '82',
  },
  {
    id: 118,
    country: 'Kuwait',
    capital: null,
    country_code: 'KW',
    nationality: null,
    dial_code: '965',
  },
  {
    id: 119,
    country: 'Kyrgyzstan',
    capital: null,
    country_code: 'KG',
    nationality: null,
    dial_code: '996',
  },
  {
    id: 120,
    country: "Lao People's Democratic Republic",
    capital: null,
    country_code: 'LA',
    nationality: null,
    dial_code: '856',
  },
  {
    id: 121,
    country: 'Latvia',
    capital: null,
    country_code: 'LV',
    nationality: null,
    dial_code: '371',
  },
  {
    id: 122,
    country: 'Lebanon',
    capital: null,
    country_code: 'LB',
    nationality: null,
    dial_code: '961',
  },
  {
    id: 123,
    country: 'Lesotho',
    capital: null,
    country_code: 'LS',
    nationality: null,
    dial_code: '266',
  },
  {
    id: 124,
    country: 'Liberia',
    capital: null,
    country_code: 'LR',
    nationality: null,
    dial_code: '231',
  },
  {
    id: 125,
    country: 'Libyan Arab Jamahiriya',
    capital: null,
    country_code: 'LY',
    nationality: null,
    dial_code: '218',
  },
  {
    id: 126,
    country: 'Liechtenstein',
    capital: null,
    country_code: 'LI',
    nationality: null,
    dial_code: '423',
  },
  {
    id: 127,
    country: 'Lithuania',
    capital: null,
    country_code: 'LT',
    nationality: null,
    dial_code: '370',
  },
  {
    id: 128,
    country: 'Luxembourg',
    capital: null,
    country_code: 'LU',
    nationality: null,
    dial_code: '352',
  },
  {
    id: 129,
    country: 'Macao',
    capital: null,
    country_code: 'MO',
    nationality: null,
    dial_code: '853',
  },
  {
    id: 130,
    country: 'Macedonia',
    capital: null,
    country_code: 'MK',
    nationality: null,
    dial_code: '389',
  },
  {
    id: 131,
    country: 'Madagascar',
    capital: null,
    country_code: 'MG',
    nationality: null,
    dial_code: '261',
  },
  {
    id: 132,
    country: 'Malawi',
    capital: null,
    country_code: 'MW',
    nationality: null,
    dial_code: '265',
  },
  {
    id: 133,
    country: 'Malaysia',
    capital: null,
    country_code: 'MY',
    nationality: null,
    dial_code: '60',
  },
  {
    id: 134,
    country: 'Maldives',
    capital: null,
    country_code: 'MV',
    nationality: null,
    dial_code: '960',
  },
  {
    id: 135,
    country: 'Mali',
    capital: null,
    country_code: 'ML',
    nationality: null,
    dial_code: '223',
  },
  {
    id: 136,
    country: 'Malta',
    capital: null,
    country_code: 'MT',
    nationality: null,
    dial_code: '356',
  },
  {
    id: 137,
    country: 'Marshall Islands',
    capital: null,
    country_code: 'MH',
    nationality: null,
    dial_code: '692',
  },
  {
    id: 138,
    country: 'Martinique',
    capital: null,
    country_code: 'MQ',
    nationality: null,
    dial_code: '596',
  },
  {
    id: 139,
    country: 'Mauritania',
    capital: null,
    country_code: 'MR',
    nationality: null,
    dial_code: '222',
  },
  {
    id: 140,
    country: 'Mauritius',
    capital: null,
    country_code: 'MU',
    nationality: null,
    dial_code: '230',
  },
  {
    id: 141,
    country: 'Mayotte',
    capital: null,
    country_code: 'YT',
    nationality: null,
    dial_code: '269',
  },
  {
    id: 142,
    country: 'Mexico',
    capital: null,
    country_code: 'MX',
    nationality: null,
    dial_code: '52',
  },
  {
    id: 143,
    country: 'Micronesia, Federated States Of',
    capital: null,
    country_code: 'FM',
    nationality: null,
    dial_code: '691',
  },
  {
    id: 144,
    country: 'Moldova',
    capital: null,
    country_code: 'MD',
    nationality: null,
    dial_code: '373',
  },
  {
    id: 145,
    country: 'Monaco',
    capital: null,
    country_code: 'MC',
    nationality: null,
    dial_code: '377',
  },
  {
    id: 146,
    country: 'Mongolia',
    capital: null,
    country_code: 'MN',
    nationality: null,
    dial_code: '976',
  },
  {
    id: 147,
    country: 'Montenegro',
    capital: null,
    country_code: 'ME',
    nationality: null,
    dial_code: '382',
  },
  {
    id: 148,
    country: 'Montserrat',
    capital: null,
    country_code: 'MS',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 149,
    country: 'Morocco',
    capital: null,
    country_code: 'MA',
    nationality: null,
    dial_code: '212',
  },
  {
    id: 150,
    country: 'Mozambique',
    capital: null,
    country_code: 'MZ',
    nationality: null,
    dial_code: '258',
  },
  {
    id: 151,
    country: 'Myanmar',
    capital: null,
    country_code: 'MM',
    nationality: null,
    dial_code: '95',
  },
  {
    id: 152,
    country: 'Namibia',
    capital: null,
    country_code: 'NA',
    nationality: null,
    dial_code: '264',
  },
  {
    id: 153,
    country: 'Nauru',
    capital: null,
    country_code: 'NR',
    nationality: null,
    dial_code: '674',
  },
  {
    id: 154,
    country: 'Nepal',
    capital: null,
    country_code: 'NP',
    nationality: null,
    dial_code: '977',
  },
  {
    id: 155,
    country: 'Netherlands',
    capital: null,
    country_code: 'NL',
    nationality: null,
    dial_code: '31',
  },
  {
    id: 156,
    country: 'Netherlands Antilles',
    capital: null,
    country_code: 'AN',
    nationality: null,
    dial_code: '599',
  },
  {
    id: 157,
    country: 'New Caledonia',
    capital: null,
    country_code: 'NC',
    nationality: null,
    dial_code: '687',
  },
  {
    id: 158,
    country: 'New Zealand',
    capital: null,
    country_code: 'NZ',
    nationality: null,
    dial_code: '64',
  },
  {
    id: 159,
    country: 'Nicaragua',
    capital: null,
    country_code: 'NI',
    nationality: null,
    dial_code: '505',
  },
  {
    id: 160,
    country: 'Niger',
    capital: null,
    country_code: 'NE',
    nationality: null,
    dial_code: '227',
  },
  {
    id: 161,
    country: 'Nigeria',
    capital: null,
    country_code: 'NG',
    nationality: null,
    dial_code: '234',
  },
  {
    id: 162,
    country: 'Niue',
    capital: null,
    country_code: 'NU',
    nationality: null,
    dial_code: '683',
  },
  {
    id: 163,
    country: 'Norfolk Island',
    capital: null,
    country_code: 'NF',
    nationality: null,
    dial_code: '6723',
  },
  {
    id: 164,
    country: 'Northern Mariana Islands',
    capital: null,
    country_code: 'MP',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 165,
    country: 'Norway',
    capital: null,
    country_code: 'NO',
    nationality: null,
    dial_code: '47',
  },
  {
    id: 166,
    country: 'Oman',
    capital: null,
    country_code: 'OM',
    nationality: null,
    dial_code: '968',
  },
  {
    id: 167,
    country: 'Pakistan',
    capital: null,
    country_code: 'PK',
    nationality: null,
    dial_code: '92',
  },
  {
    id: 168,
    country: 'Palau',
    capital: null,
    country_code: 'PW',
    nationality: null,
    dial_code: '680',
  },
  {
    id: 169,
    country: 'Palestine, State of',
    capital: null,
    country_code: 'PS',
    nationality: null,
    dial_code: '970',
  },
  {
    id: 170,
    country: 'Panama',
    capital: null,
    country_code: 'PA',
    nationality: null,
    dial_code: '507',
  },
  {
    id: 171,
    country: 'Papua New Guinea',
    capital: null,
    country_code: 'PG',
    nationality: null,
    dial_code: '675',
  },
  {
    id: 172,
    country: 'Paraguay',
    capital: null,
    country_code: 'PY',
    nationality: null,
    dial_code: '595',
  },
  {
    id: 173,
    country: 'Peru',
    capital: null,
    country_code: 'PE',
    nationality: null,
    dial_code: '51',
  },
  {
    id: 174,
    country: 'Philippines',
    capital: null,
    country_code: 'PH',
    nationality: null,
    dial_code: '63',
  },
  {
    id: 175,
    country: 'Pitcairn',
    capital: null,
    country_code: 'PN',
    nationality: null,
    dial_code: '64',
  },
  {
    id: 176,
    country: 'Poland',
    capital: null,
    country_code: 'PL',
    nationality: null,
    dial_code: '48',
  },
  {
    id: 177,
    country: 'Portugal',
    capital: null,
    country_code: 'PT',
    nationality: null,
    dial_code: '351',
  },
  {
    id: 178,
    country: 'Puerto Rico',
    capital: null,
    country_code: 'PR',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 179,
    country: 'Qatar',
    capital: null,
    country_code: 'QA',
    nationality: null,
    dial_code: '974',
  },
  {
    id: 180,
    country: 'Reunion',
    capital: null,
    country_code: 'RE',
    nationality: null,
    dial_code: '262',
  },
  {
    id: 181,
    country: 'Romania',
    capital: null,
    country_code: 'RO',
    nationality: null,
    dial_code: '40',
  },
  {
    id: 182,
    country: 'Russian Federation',
    capital: null,
    country_code: 'RU',
    nationality: null,
    dial_code: '7',
  },
  {
    id: 183,
    country: 'Rwanda',
    capital: null,
    country_code: 'RW',
    nationality: null,
    dial_code: '250',
  },
  {
    id: 184,
    country: 'Saint Barthelemy',
    capital: null,
    country_code: 'BL',
    nationality: null,
    dial_code: '590',
  },
  {
    id: 185,
    country: 'Saint Helena',
    capital: null,
    country_code: 'SH',
    nationality: null,
    dial_code: '290',
  },
  {
    id: 186,
    country: 'Saint Kitts And Nevis',
    capital: null,
    country_code: 'KN',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 187,
    country: 'Saint Lucia',
    capital: null,
    country_code: 'LC',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 188,
    country: 'Saint Martin',
    capital: null,
    country_code: 'MF',
    nationality: null,
    dial_code: '590',
  },
  {
    id: 189,
    country: 'Saint Pierre And Miquelon',
    capital: null,
    country_code: 'PM',
    nationality: null,
    dial_code: '508',
  },
  {
    id: 190,
    country: 'Saint Vincent And Grenadines',
    capital: null,
    country_code: 'VC',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 191,
    country: 'Samoa',
    capital: null,
    country_code: 'WS',
    nationality: null,
    dial_code: '685',
  },
  {
    id: 192,
    country: 'San Marino',
    capital: null,
    country_code: 'SM',
    nationality: null,
    dial_code: '378',
  },
  {
    id: 193,
    country: 'Sao Tome And Principe',
    capital: null,
    country_code: 'ST',
    nationality: null,
    dial_code: '239',
  },
  {
    id: 194,
    country: 'Saudi Arabia',
    capital: null,
    country_code: 'SA',
    nationality: null,
    dial_code: '966',
  },
  {
    id: 195,
    country: 'Senegal',
    capital: null,
    country_code: 'SN',
    nationality: null,
    dial_code: '221',
  },
  {
    id: 196,
    country: 'Serbia',
    capital: null,
    country_code: 'RS',
    nationality: null,
    dial_code: '381',
  },
  {
    id: 197,
    country: 'Seychelles',
    capital: null,
    country_code: 'SC',
    nationality: null,
    dial_code: '248',
  },
  {
    id: 198,
    country: 'Sierra Leone',
    capital: null,
    country_code: 'SL',
    nationality: null,
    dial_code: '232',
  },
  {
    id: 199,
    country: 'Singapore',
    capital: null,
    country_code: 'SG',
    nationality: null,
    dial_code: '65',
  },
  {
    id: 200,
    country: 'Slovakia',
    capital: null,
    country_code: 'SK',
    nationality: null,
    dial_code: '421',
  },
  {
    id: 201,
    country: 'Slovenia',
    capital: null,
    country_code: 'SI',
    nationality: null,
    dial_code: '386',
  },
  {
    id: 202,
    country: 'Solomon Islands',
    capital: null,
    country_code: 'SB',
    nationality: null,
    dial_code: '677',
  },
  {
    id: 203,
    country: 'Somalia',
    capital: null,
    country_code: 'SO',
    nationality: null,
    dial_code: '252',
  },
  {
    id: 204,
    country: 'South Africa',
    capital: null,
    country_code: 'ZA',
    nationality: null,
    dial_code: '27',
  },
  {
    id: 205,
    country: 'South Georgia And Sandwich Isl.',
    capital: null,
    country_code: 'GS',
    nationality: null,
    dial_code: '500',
  },
  {
    id: 206,
    country: 'Spain',
    capital: null,
    country_code: 'ES',
    nationality: null,
    dial_code: '34',
  },
  {
    id: 207,
    country: 'Sri Lanka',
    capital: null,
    country_code: 'LK',
    nationality: null,
    dial_code: '94',
  },
  {
    id: 208,
    country: 'Sudan',
    capital: null,
    country_code: 'SD',
    nationality: null,
    dial_code: '249',
  },
  {
    id: 209,
    country: 'Suriname',
    capital: null,
    country_code: 'SR',
    nationality: null,
    dial_code: '597',
  },
  {
    id: 210,
    country: 'Svalbard And Jan Mayen',
    capital: null,
    country_code: 'SJ',
    nationality: null,
    dial_code: '47',
  },
  {
    id: 211,
    country: 'Swaziland',
    capital: null,
    country_code: 'SZ',
    nationality: null,
    dial_code: '268',
  },
  {
    id: 212,
    country: 'Sweden',
    capital: null,
    country_code: 'SE',
    nationality: null,
    dial_code: '46',
  },
  {
    id: 213,
    country: 'Switzerland',
    capital: null,
    country_code: 'CH',
    nationality: null,
    dial_code: '41',
  },
  {
    id: 214,
    country: 'Syrian Arab Republic',
    capital: null,
    country_code: 'SY',
    nationality: null,
    dial_code: '963',
  },
  {
    id: 215,
    country: 'Taiwan',
    capital: null,
    country_code: 'TW',
    nationality: null,
    dial_code: '886',
  },
  {
    id: 216,
    country: 'Tajikistan',
    capital: null,
    country_code: 'TJ',
    nationality: null,
    dial_code: '992',
  },
  {
    id: 217,
    country: 'Tanzania',
    capital: null,
    country_code: 'TZ',
    nationality: null,
    dial_code: '255',
  },
  {
    id: 218,
    country: 'Thailand',
    capital: null,
    country_code: 'TH',
    nationality: null,
    dial_code: '66',
  },
  {
    id: 219,
    country: 'Timor-Leste',
    capital: null,
    country_code: 'TL',
    nationality: null,
    dial_code: '670',
  },
  {
    id: 220,
    country: 'Togo',
    capital: null,
    country_code: 'TG',
    nationality: null,
    dial_code: '228',
  },
  {
    id: 221,
    country: 'Tokelau',
    capital: null,
    country_code: 'TK',
    nationality: null,
    dial_code: '690',
  },
  {
    id: 222,
    country: 'Tonga',
    capital: null,
    country_code: 'TO',
    nationality: null,
    dial_code: '676',
  },
  {
    id: 223,
    country: 'Trinidad And Tobago',
    capital: null,
    country_code: 'TT',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 224,
    country: 'Tunisia',
    capital: null,
    country_code: 'TN',
    nationality: null,
    dial_code: '216',
  },
  {
    id: 225,
    country: 'Turkey',
    capital: null,
    country_code: 'TR',
    nationality: null,
    dial_code: '90',
  },
  {
    id: 226,
    country: 'Turkmenistan',
    capital: null,
    country_code: 'TM',
    nationality: null,
    dial_code: '993',
  },
  {
    id: 227,
    country: 'Turks And Caicos Islands',
    capital: null,
    country_code: 'TC',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 228,
    country: 'Tuvalu',
    capital: null,
    country_code: 'TV',
    nationality: null,
    dial_code: '688',
  },
  {
    id: 229,
    country: 'Uganda',
    capital: null,
    country_code: 'UG',
    nationality: null,
    dial_code: '256',
  },
  {
    id: 230,
    country: 'Ukraine',
    capital: null,
    country_code: 'UA',
    nationality: null,
    dial_code: '380',
  },
  {
    id: 231,
    country: 'United Arab Emirates',
    capital: null,
    country_code: 'AE',
    nationality: null,
    dial_code: '971',
  },
  {
    id: 232,
    country: 'United Kingdom',
    capital: null,
    country_code: 'GB',
    nationality: null,
    dial_code: '44',
  },
  {
    id: 233,
    country: 'United States',
    capital: null,
    country_code: 'US',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 234,
    country: 'United States Outlying Islands',
    capital: null,
    country_code: 'UM',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 235,
    country: 'Uruguay',
    capital: null,
    country_code: 'UY',
    nationality: null,
    dial_code: '598',
  },
  {
    id: 236,
    country: 'Uzbekistan',
    capital: null,
    country_code: 'UZ',
    nationality: null,
    dial_code: '998',
  },
  {
    id: 237,
    country: 'Vanuatu',
    capital: null,
    country_code: 'VU',
    nationality: null,
    dial_code: '678',
  },
  {
    id: 238,
    country: 'Venezuela',
    capital: null,
    country_code: 'VE',
    nationality: null,
    dial_code: '58',
  },
  {
    id: 239,
    country: 'Viet Nam',
    capital: null,
    country_code: 'VN',
    nationality: null,
    dial_code: '84',
  },
  {
    id: 240,
    country: 'Virgin Islands, British',
    capital: null,
    country_code: 'VG',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 241,
    country: 'Virgin Islands, U.S.',
    capital: null,
    country_code: 'VI',
    nationality: null,
    dial_code: '1',
  },
  {
    id: 242,
    country: 'Wallis And Futuna',
    capital: null,
    country_code: 'WF',
    nationality: null,
    dial_code: '681',
  },
  {
    id: 243,
    country: 'Western Sahara',
    capital: null,
    country_code: 'EH',
    nationality: null,
    dial_code: '212',
  },
  {
    id: 244,
    country: 'Yemen',
    capital: null,
    country_code: 'YE',
    nationality: null,
    dial_code: '967',
  },
  {
    id: 245,
    country: 'Zambia',
    capital: null,
    country_code: 'ZM',
    nationality: null,
    dial_code: '260',
  },
  {
    id: 246,
    country: 'Zimbabwe',
    capital: null,
    country_code: 'ZW',
    nationality: null,
    dial_code: '263',
  },
];

export const UNIVERSITIES = [
  {
    rank: '1',
    name: 'Massachusetts Institute of Technology (MIT)',
    country: 'United States',
  },
  {rank: '2', name: 'University of Oxford', country: 'United Kingdom'},
  {rank: '3=', name: 'Stanford University', country: 'United States'},
  {rank: '3=', name: 'University of Cambridge', country: 'United Kingdom'},
  {rank: '5', name: 'Harvard University', country: 'United States'},
  {
    rank: '6',
    name: 'California Institute of Technology (Caltech)',
    country: 'United States',
  },
  {rank: '7', name: 'Imperial College London', country: 'United Kingdom'},
  {
    rank: '8=',
    name: 'ETH Zurich - Swiss Federal Institute of Technology',
    country: 'Switzerland',
  },
  {rank: '8=', name: 'UCL', country: 'United Kingdom'},
  {rank: '10', name: 'University of Chicago', country: 'United States'},
  {
    rank: '11',
    name: 'National University of Singapore (NUS)',
    country: 'Singapore',
  },
  {
    rank: '12',
    name: 'Nanyang Technological University, Singapore (NTU)',
    country: 'Singapore',
  },
  {rank: '13', name: 'University of Pennsylvania', country: 'United States'},
  {rank: '14=', name: 'EPFL', country: 'Switzerland'},
  {rank: '14=', name: 'Yale University', country: 'United States'},
  {rank: '16', name: 'The University of Edinburgh', country: 'United Kingdom'},
  {rank: '17', name: 'Tsinghua University', country: ' China'},
  {rank: '18', name: 'Peking University', country: 'China'},
  {rank: '19', name: 'Columbia University', country: 'United States'},
  {rank: '20', name: 'Princeton University', country: 'United States'},
  {rank: '21', name: 'Cornell University', country: 'United States'},
  {rank: '22', name: 'The University of Hong Kong', country: 'Hong Kong SAR'},
  {rank: '23=', name: 'The University of Tokyo', country: 'Japan'},
  {
    rank: '23=',
    name: 'University of Michigan-Ann Arbor',
    country: 'United States',
  },
  {rank: '25', name: 'Johns Hopkins University', country: 'United States'},
  {rank: '26', name: 'University of Toronto', country: 'Canada'},
  {rank: '27=', name: 'McGill University', country: 'Canada'},
  {
    rank: '27=',
    name: 'The Australian National University',
    country: 'Australia',
  },
  {
    rank: '27=',
    name: 'The University of Manchester',
    country: ' United Kingdom',
  },
  {rank: '30', name: 'Northwestern University', country: 'United States'},
  {rank: '31', name: 'Fudan University', country: 'China'},
  {
    rank: '32',
    name: 'University of California, Berkeley (UCB)',
    country: 'United States',
  },
  {rank: '33', name: 'Kyoto University', country: 'Japan'},
  {
    rank: '34',
    name: 'The Hong Kong University of Science and Technology',
    country: 'Hong Kong',
  },
  {rank: '35', name: 'King`s College London', country: 'United Kingdom'},
  {rank: '36', name: 'Seoul National University', country: 'South Korea'},
  {rank: '37', name: 'The University of Melbourne', country: 'Australia'},
  {rank: '38', name: 'The University of Sydney', country: 'Australia'},
  {
    rank: '39',
    name: 'The Chinese University of Hong Kong (CUHK)',
    country: 'Hong Kong',
  },
  {
    rank: '40',
    name: 'University of California, Los Angeles (UCLA)',
    country: 'United States',
  },
  {
    rank: '41',
    name: 'KAIST - Korea Advanced Institute of Science & Technology',
    country: 'South Korea',
  },
  {rank: '42', name: 'New York University (NYU)', country: 'United States'},
  {
    rank: '43',
    name: 'The University of New South Wales (UNSW Sydney)',
    country: 'Australia',
  },
  {rank: '44', name: 'Université PSL', country: 'France'},
  {rank: '45', name: 'Zhejiang University', country: 'China'},
  {rank: '46', name: 'University of British Columbia', country: 'Canada'},
  {rank: '47', name: 'The University of Queensland', country: 'Australia'},
  {
    rank: '48',
    name: 'University of California, San Diego (UCSD)',
    country: 'United States',
  },
  {
    rank: '49=',
    name: 'Institute Polytechnique de Paris',
    country: 'France',
  },
  {
    rank: '49',
    name: 'The London School of Economics and Political Science (LSE)',
    country: 'United Kingdom',
  },
  {
    rank: '50=',
    name: 'Shanghai Jiao Tong University',
    country: 'China',
  },
  {rank: '50=', name: 'Technical University of Munich', country: 'Germany'},
  {rank: '52', name: 'Duke University', country: 'United States'},
  {rank: '53=', name: 'Carnegie Mellon University', country: 'United States'},
  {
    rank: '53=',
    name: 'City University of Hong Kong',
    country: 'Hong Kong',
  },
  {rank: '55', name: 'University of Amsterdam', country: 'Netherlands'},
  {
    rank: '56',
    name: 'Tokyo Institute of Technology (Tokyo Tech)',
    country: 'Japan',
  },
  {rank: '57', name: 'Delft University of Technology', country: 'Netherlands'},
  {rank: '58', name: 'Monash University', country: 'Australia'},
  {rank: '60', name: 'Brown University', country: 'United States'},
  {rank: '61', name: 'The University of Warwick', country: 'United Kingdom'},
  {rank: '62', name: 'University of Bristol', country: 'United Kingdom'},
  {
    rank: '63',
    name: 'Ruprecht-Karls-Universität Heidelberg',
    country: 'Germany',
  },
  {
    rank: '64',
    name: 'Ludwig-Maximilians-Universität München',
    country: 'Germany',
  },
  {
    rank: '65',
    name: 'Universiti Malaya (UM)',
    country: 'Malaysia',
  },
  {
    rank: '66',
    name: 'The Hong Kong Polytechnic University',
    country: 'Hong Kong',
  },
  {
    rank: '67',
    name: 'University of Texas at Austin',
    country: 'United States',
  },
  {
    rank: '68',
    name: 'National Taiwan University (NTU)',
    country: 'Taiwan',
  },
  {
    rank: '69',
    name: 'Universidad de Buenos Aires (UBA)',
    country: 'Argentina',
  },
  {
    rank: '70=',
    name: 'KU Leuven',
    country: 'Belgium',
  },
  {
    rank: '70=',
    name: 'University of Zurich',
    country: 'Switzerland',
  },
  {
    rank: '72',
    name: 'Sorbonne University',
    country: 'France',
  },
  {
    rank: '73',
    name: 'University of Glasgow',
    country: 'United Kingdom',
  },
  {
    rank: '74',
    name: 'Korea University',
    country: 'South Korea',
  },
  {
    rank: '75=',
    name: 'Osaka University',
    country: 'Japan',
  },
  {
    rank: '75=',
    name: 'University of Wisconsin-Madison',
    country: 'United States',
  },
  {
    rank: '77',
    name: 'University of Southampton',
    country: 'United Kingdom',
  },
  {
    rank: '78',
    name: 'Lomonosov Moscow State University',
    country: 'Russia',
  },
  {
    rank: '79=',
    name: 'University of Copenhagen',
    country: 'Denmark',
  },
  {
    rank: '79=',
    name: 'Yonsei University',
    country: 'South Korea',
  },
  {
    rank: '81',
    name: 'Pohang University of Science And Technology (POSTECH)',
    country: 'South Korea',
  },
  {
    rank: '82=',
    name: 'Durham University',
    country: 'United Kingdom',
  },
  {
    rank: '82=',
    name: 'Tohoku University',
    country: 'Japan',
  },
  {
    rank: '82=',
    name: 'University of Illinois at Urbana-Champaign ',
    country: 'United States',
  },
  {
    rank: '85=',
    name: 'The University of Auckland',
    country: 'New Zealand',
  },
  {
    rank: '85=',
    name: 'University of Washington',
    country: 'United States',
  },
  {
    rank: '86=',
    name: 'Université Paris-Saclay',
    country: 'France',
  },
  {
    rank: '87',
    name: 'Lund University ',
    country: 'Sweden',
  },
  {
    rank: '88',
    name: 'Georgia Institute of Technology',
    country: 'United States',
  },
  {
    rank: '89',
    name: 'KTH Royal Institute of Technology',
    country: 'Sweden',
  },
  {
    rank: '90',
    name: 'University of Birmingham',
    country: 'United Kingdom',
  },
  {
    rank: '91',
    name: 'University of St Andrews',
    country: 'United Kingdom',
  },
  {
    rank: '92',
    name: 'University of Leeds',
    country: 'United Kingdom',
  },
  {
    rank: '93',
    name: 'The University of Western Australia',
    country: 'Australia',
  },
  {
    rank: '94',
    name: 'Rice University',
    country: ' United States',
  },
  {
    rank: '95',
    name: 'The University of Sheffield',
    country: ' United Kingdom',
  },
  {
    rank: '96',
    name: 'Pennsylvania State University',
    country: 'United States',
  },
  {
    rank: '97',
    name: 'Sungkyunkwan University(SKKU)',
    country: 'South Korea',
  },
  {
    rank: '98',
    name: 'University of Science and Technology of China',
    country: 'China',
  },
  {
    rank: '99',
    name: 'Technical University of Denmark',
    country: 'Denmark',
  },
  {
    rank: '100',
    name: ' University of North Carolina, Chapel Hill',
    country: 'United States',
  },
  {
    rank: '101 ',
    name: ' Trinity College Dublin, The University of Dublin',
    country: 'Ireland',
  },
  {
    rank: '102',
    name: ' University of Oslo',
    country: 'Norway',
  },
  {
    rank: '103',
    name: ' University of Nottingham',
    country: 'United Kingdom',
  },
  {
    rank: '104',
    name: ' University of Helsinki',
    country: ' Finland',
  },
  {
    rank: '105=',
    name: 'Universidad Nacional Autónoma de México  (UNAM)',
    country: 'Mexico',
  },
  {
    rank: '105=',
    name: 'University of Geneva',
    country: 'Switzerland',
  },
  {
    rank: '107',
    name: 'Washington University in St. Louis',
    country: 'United States',
  },
  {
    rank: '108',
    name: 'The University of Adelaide',
    country: 'Australia',
  },
  {
    rank: '108=',
    name: 'University of California, Davis',
    country: 'United States',
  },
  {
    rank: '109',
    name: 'King Abdulaziz University (KAU)',
    country: 'Saudi Arabia',
  },
  {
    rank: '110',
    name: 'Utrecht University',
    country: 'Netherlands',
  },
  {
    rank: '111',
    name: 'Université de Montréal',
    country: 'Canada',
  },
  {
    rank: '112=',
    name: 'Aalto University',
    country: 'Finland',
  },
  {
    rank: '112=',
    name: 'Boston University',
    country: 'United States',
  },
  {
    rank: '112=',
    name: 'Leiden University',
    country: 'Netherlands',
  },
  {
    rank: '112=',
    name: 'University of Southern California',
    country: 'United States',
  },
  {
    rank: '116',
    name: 'Purdue University',
    country: 'United States',
  },
  {
    rank: '117',
    name: 'Queen Mary University of London',
    country: 'United Kingdom',
  },
  {
    rank: '118',
    name: 'Nagoya University',
    country: 'Japan',
  },
  {
    rank: '119',
    name: 'University of Bern',
    country: 'Switzerland',
  },
  {
    rank: '120',
    name: 'The Ohio State University',
    country: 'United States',
  },
  {
    rank: '121=',
    name: 'Chalmers University of Technology',
    country: 'Sweden',
  },
  {
    rank: '121=',
    name: 'Universidade de São Paulo',
    country: 'Brazil',
  },
  {
    rank: '123',
    name: 'Wageningen University & Research',
    country: 'Netherlands',
  },
  {
    rank: '124',
    name: 'Uppsala University',
    country: 'Sweden',
  },
  {
    rank: '125',
    name: 'Eindhoven University of Technology',
    country: 'Netherlands',
  },
  {
    rank: '126',
    name: 'University of Alberta',
    country: 'Canada',
  },
  {
    rank: '127',
    name: 'Freie Universitaet Berlin',
    country: 'Germany',
  },
  {
    rank: '128=',
    name: 'Humboldt-Universität zu Berlin',
    country: 'Germany',
  },
  {
    rank: '128=',
    name: 'University of Groningen',
    country: 'Netherlands',
  },
  {
    rank: '130',
    name: 'École Normale Supérieure de Lyon',
    country: 'France',
  },
  {
    rank: '131',
    name: 'Nanjing University',
    country: 'China',
  },
  {
    rank: '132',
    name: 'Lancaster University',
    country: 'United Kingdom',
  },
  {
    rank: '133',
    name: 'University of Technology Sydney',
    country: 'Australia',
  },
  {
    rank: '134',
    name: 'Newcastle University',
    country: 'United Kingdom',
  },
  {
    rank: '135',
    name: 'Pontificia Universidad Católica de Chile (UC)',
    country: 'Chile',
  },
  {
    rank: '136',
    name: 'KIT, Karlsruhe Institute of Technology',
    country: 'Germany',
  },
  {
    rank: '137',
    name: 'Kyushu University',
    country: 'Japan',
  },
  {
    rank: '138=',
    name: 'University of Basel',
    country: 'Switzerland',
  },
  {
    rank: '140',
    name: 'McMaster University',
    country: 'Canada',
  },
  {
    rank: '141',
    name: 'Ghent University',
    country: 'Belgium',
  },
  {
    rank: '142',
    name: 'Politecnico di Milano',
    country: 'Italy',
  },
  {
    rank: '143',
    name: 'Universiti Putra Malaysia (UPM)',
    country: 'Malaysia',
  },
  {
    rank: '144',
    name: 'Universiti Kebangsaan Malaysia (UKM)',
    country: 'Malaysia',
  },
  {
    rank: '145',
    name: 'Hokkaido University',
    country: 'Japan',
  },
  {
    rank: '146',
    name: 'University of California, Santa Barbara (UCSB)',
    country: ' United States',
  },
  {
    rank: '147 ',
    name: ' Universiti Sains Malaysia (USM) ',
    country: '  Malaysia',
  },
  {
    rank: '148',
    name: 'Stockholm University',
    country: 'Sweden',
  },
  {
    rank: '149=',
    name: 'The University of Exeter',
    country: 'United Kingdom',
  },
  {
    rank: '149=',
    name: 'University of Waterloo',
    country: 'Canada',
  },
  {
    rank: '151=',
    name: 'Cardiff University',
    country: 'United Kingdom',
  },
  {
    rank: '151=',
    name: 'University of Vienna',
    country: 'Austria',
  },
  {
    rank: '151=',
    name: 'University of York',
    country: 'United Kingdom',
  },
  {
    rank: '154',
    name: 'University of Rochester',
    country: 'United States',
  },
  {
    rank: '155',
    name: 'Aarhus University',
    country: 'Denmark',
  },
  {
    rank: '156',
    name: 'Hanyang University',
    country: 'South Korea',
  },
  {
    rank: '157',
    name: 'Michigan State University',
    country: 'United States',
  },
  {
    rank: '158',
    name: 'University of Maryland, College Park',
    country: 'United States',
  },
  {
    rank: '159',
    name: 'Technische Universität Berlin (TU Berlin)',
    country: 'Germany',
  },
  {
    rank: '160',
    name: 'Emory University',
    country: 'United States',
  },
  {
    rank: '161=',
    name: 'Case Western Reserve University',
    country: 'United States',
  },
  {
    rank: '161=',
    name: 'Tecnológico de Monterrey',
    country: 'Mexico',
  },
  {
    rank: '163=',
    name: 'King Fahd University of Petroleum & Minerals (KFUPM)',
    country: 'Saudi Arabia',
  },
  {
    rank: '163=',
    name: 'University of Pittsburgh',
    country: 'United States',
  },
  {
    rank: '165 ',
    name: ' RWTH Aachen University',
    country: ' Germany',
  },
  {
    rank: '166=',
    name: 'Alma Mater Studiorum - University of Bologna',
    country: 'Italy',
  },
  {
    rank: '166=',
    name: 'University of Bath',
    country: 'United Kingdom',
  },
  {
    rank: '168=',
    name: 'Texas A&M University',
    country: 'United States',
  },
  {
    rank: '168=',
    name: 'Universitat de Barcelona',
    country: 'Spain',
  },
  {
    rank: '170',
    name: 'Western University ',
    country: 'Canada',
  },
  {
    rank: '171',
    name: 'Sapienza University of Rome',
    country: 'Italy',
  },
  {
    rank: '172',
    name: 'Albert-Ludwigs-Universitaet Freiburg',
    country: 'Germany',
  },
  {
    rank: '173=',
    name: 'University College Dublin',
    country: 'Ireland',
  },
  {
    rank: '173=',
    name: 'University of Florida',
    country: 'United States',
  },
  {
    rank: '175 ',
    name: ' Al-Farabi Kazakh National University',
    country: 'Kazakhstan',
  },
  {
    rank: '176 ',
    name: ' University of Lausanne',
    country: ' Switzerland',
  },
  {
    rank: '177=',
    name: 'Eberhard Karls Universität Tübingen ',
    country: ' Germany',
  },
  {
    rank: '177=',
    name: 'Indian Institute of Technology Bombay (IITB)',
    country: 'India',
  },
  {
    rank: '179',
    name: 'Erasmus University Rotterdam',
    country: 'Netherlands',
  },
  {
    rank: '180=',
    name: 'National Tsing Hua University ',
    country: 'Taiwan',
  },
  {
    rank: '180=',
    name: 'Technische Universität Wien',
    country: 'Austria',
  },
  {
    rank: '180=',
    name: 'University of Gothenburg',
    country: 'Sweden',
  },
  {
    rank: '183=',
    name: 'Khalifa University of Science and Technology',
    country: 'United Arab Emirates',
  },
  {
    rank: '183=',
    name: 'Universidad de Chile',
    country: 'Chile',
  },
  {
    rank: '185',
    name: 'Indian Institute of Technology Delhi (IITD)',
    country: 'India',
  },
  {
    rank: '186=',
    name: 'Indian Institute of Science',
    country: 'India',
  },
  {
    rank: '186=',
    name: 'University of Minnesota Twin Cities ',
    country: 'United States',
  },
  {
    rank: '188 ',
    name: ' Université catholique de Louvain (UCLouvain)',
    country: 'Belgium',
  },
  {
    rank: '189=',
    name: 'University of Liverpool',
    country: 'United Kingdom',
  },
  {
    rank: '189=',
    name: 'University of Twente',
    country: 'Netherlands',
  },
  {
    rank: '191=',
    name: 'Dartmouth College',
    country: 'United States',
  },
  {
    rank: '191=',
    name: 'Universiti Teknologi Malaysia',
    country: 'Malaysia',
  },
  {
    rank: '193',
    name: 'University of Wollongong',
    country: 'Australia',
  },
  {
    rank: '194=',
    name: 'Curtin University',
    country: 'Australia',
  },
  {
    rank: '194=',
    name: 'Technische Universität Dresden',
    country: 'Germany',
  },
  {
    rank: '194=',
    name: 'University of Otago	New',
    country: 'Zealand',
  },
  {
    rank: '197',
    name: 'The University of Newcastle, Australia (UON)',
    country: 'Australia',
  },
  {
    rank: '198',
    name: 'The Hebrew University of Jerusalem',
    country: 'Israel',
  },
  {
    rank: '199',
    name: 'University of Bergen',
    country: 'Norway',
  },
  {
    rank: '200',
    name: ' Macquarie University',
    country: 'Australia',
  },
  {
    rank: '277=',
    name: 'King Saud University',
    country: 'Saudi Arabia',
  },
  {
    rank: '447=',
    name: 'Umm Al-Qura University',
    country: 'Saudi Arabia',
  },
  {
    rank: '521-530',
    name: 'Imam Abdulrahman Bin Faisal University (IAU) (formerly UNIVERSITY OF DAMMAM)',
    country: 'Saudi Arabia',
  },
  {
    rank: '651-700',
    name: 'King Khalid University',
    country: 'Saudi Arabia',
  },
  {
    rank: '701-750',
    name: 'Jouf University',
    country: 'Saudi Arabia',
  },
  {
    rank: '701-750',
    name: 'Prince Mohammad Bin Fahd university',
    country: 'Saudi Arabia',
  },
  {
    rank: '751-800',
    name: 'Princess Nourah bint Abdulrahman University',
    country: 'Saudi Arabia',
  },
  {
    rank: '801-1000',
    name: 'Islamic University of Madinah',
    country: 'Saudi Arabia',
  },
  {
    rank: '801-1000',
    name: 'King Faisal University',
    country: 'Saudi Arabia',
  },
  {
    rank: '801-1000',
    name: 'Qassim University',
    country: 'Saudi Arabia',
  },
  {
    rank: '1001-1200',
    name: '	Imam Mohammad Ibn Saud Islamic University - IMSIU',
    country: 'Saudi Arabia',
  },
  {
    rank: '1001-1200',
    name: 'Taibah University',
    country: 'Saudi Arabia',
  },
  {
    rank: '',
    name: 'King Abdullah University of Science & Technology (KAUST)',
    country: 'Saudi Arabia',
  },
  {
    rank: '',
    name: 'Alfaisal University',
    country: 'Saudi Arabia',
  },
  {
    rank: '',
    name: 'Al Yamamah University',
    country: 'Saudi Arabia',
  },
  {rank: '', name: 'Others', country: 'empty'},
];

export const COUNTRIES_LIST = COUNTRIES.map((item) => {
  return {
    value: item.country,
    label: item.country,
  };
});

export const COUNTRIES_DIAL_CODES = COUNTRIES.map((item) => {
  const dialCode = `+${item.dial_code}`;
  return {
    value: `${item.country}:${dialCode}`,
    label: `${dialCode}(${item.country_code})`,
  };
});
