import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  Route,
  // Redirect,
} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {ScrollToTop} from '@components';
import Register from '@modules/register';
import Home from '@pages/Home';
import Faqs from '@pages/Faqs';

import PublicRoute from './routes/PublicRoute';

import './App.scss';

const AnimatedApp = () => {
  const location = useLocation();
  return (
    <div id="animation-root">
      <TransitionGroup>
        {/*
      This is no different than other usage of
      <CSSTransition>, just make sure to pass
      `location` to `Switch` so it can match
      the old location as it animates out.
    */}
        <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
          <Switch location={location}>
            <PublicRoute exact path="/register">
              <Register />

              {/* <Redirect
                to={{
                  pathname: '/',
                }}
              /> */}
            </PublicRoute>
            <PublicRoute exact path="/faqs">
              <Faqs />
            </PublicRoute>
            <PublicRoute exact path="/">
              <Home />
            </PublicRoute>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="*">
          <AnimatedApp />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
