import React from 'react';
import {SpinnerWrapper} from './Loader.style';

const Loader = ({
  minHeight = '100%',
  background = null,
  spinnerColor = '#fff',
}) => {
  const styles = {minHeight};
  if (background) {
    styles.backgroundColor = background;
  }
  return (
    <SpinnerWrapper
      className="animate__animated animate__fadeIn"
      style={styles}
      spinnerColor={spinnerColor}
    >
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </SpinnerWrapper>
  );
};

export {Loader};
