import React from 'react';
import {useTranslation} from 'react-i18next';

import {StyledH3} from '@styled';

const CompleteView = () => {
  const [t] = useTranslation();
  return (
    <div className="mt-5">
      <section className="content">
        <div className="container-fluid  px-0">
          <div className="card mx-auto w-lg-40">
            <div className="card-body">
              <StyledH3 className="mb-5 mt-0 text-center">
                {t('register.complete.title')}
              </StyledH3>
              <div className="text-center">
                {t('register.complete.description')}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompleteView;
