import React from 'react';
import styled from 'styled-components';
import {StyledH1} from '@styled';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
// import SelaLogoDark from '@app/assets/png/sela-dark.png';
// import SelaLogoLight from '@app/assets/png/sela-light.png';
import {ReactComponent as SelaLogoDark} from '@app/assets/svg/sela-final.svg';

// const StyledImage = styled.img`
//   width: 250px;
//   height: auto;
// `;

const StyledHeader = styled.div`
  width: 100%;
  height: 220px;
  background-color: ${({version}) => (version === 'dark' ? '#061d6d' : '#fff')};
  ${'' /* background-image: linear-gradient(1deg,#fffdf4,#fff); */}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  & .action-links {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    .action-links {
      position: static;
    }
  }
  & .action-links .active-link {
    color: #0056b3;
    font-weight: 500;
  }
`;

const navLinks = [
  {name: 'home', caption: 'Home', url: '/', transKeyName: 'homeLink'},
  {name: 'faqs', caption: 'Faqs', url: '/faqs', transKeyName: 'faqsLink'},
  {
    name: 'register',
    caption: 'Apply Here',
    url: '/register',
    transKeyName: 'registerLink',
  },
];

const MainHeader = ({page}) => {
  const [t] = useTranslation();
  const version = 'light';
  return (
    <StyledHeader className="elevation-1" version={version}>
      <div className="mb-2 mb-lg-0 pt-3 pt-lg-0">
        {/* <StyledImage src={version === 'dark' ? SelaLogoLight : SelaLogoDark} /> */}
        <SelaLogoDark style={{width: '250px'}} />
      </div>
      <StyledH1
        className="ml-0 ml-lg-5 mb-2 mb-lg-0"
        style={{
          color: version === 'dark' ? 'rgb(201 200 186)' : '#282C68',
          flex: 0.5,
        }}
      >
        {t(`register.pageTitle${capitalize(page)}`)}
      </StyledH1>
      <div className="action-links mb-2 mb-lg-0">
        {navLinks.map((item, index) => {
          if (item.name === page) {
            return (
              <div className="active-link" key={item.name}>
                {t(`register.${item.transKeyName}`)}
                {index + 1 < navLinks.length && (
                  <span className="mx-2 text-muted">|</span>
                )}
              </div>
            );
          }
          return (
            <div key={item.name}>
              <Link to={item.url}>{t(`register.${item.transKeyName}`)}</Link>
              {index + 1 < navLinks.length && (
                <span className="mx-2 text-muted">|</span>
              )}
            </div>
          );
        })}
      </div>
    </StyledHeader>
  );
};

export default MainHeader;
