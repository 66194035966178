import React from 'react';
import * as Yup from 'yup';
import 'yup-phone';
import {Row, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Input, Form, DropdownField, MainLoader} from '@components';
import {BlueButton, StyledH3} from '@styled';
import {
  COUNTRIES_DIAL_CODES,
  ENGLISH_PROFICIENCY,
  GENDER_LIST,
  NATIONALITY,
} from '@app/utils/constants';
import {isNumber} from 'lodash';

const UserRegister = ({data, handleNext}) => {
  const [t] = useTranslation();
  const [isLoading, toggleLoading] = React.useState(false);
  const [dialCodeCountry, setDialCodeCountry] = React.useState(' ');

  React.useEffect(() => {
    if (data.countryDialCode) {
      setDialCodeCountry(data.countryDialCode.value.split(':').shift());
    }
  }, [data]);

  const handleSubmit = (values) => {
    toggleLoading(true);
    setTimeout(() => {
      toggleLoading(false);
      handleNext(1, values);
    }, 500);
  };

  return (
    <>
      {isLoading && (
        <MainLoader
          minHeight="100vh"
          spinnerColor="#282c68"
          background="rgba(0,0,0,0)"
        />
      )}
      <div>
        <Form
          initialValues={{
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            firstName_AR: data.firstName_AR || '',
            lastName_AR: data.lastName_AR || '',
            nationality: data.nationality || '',
            email: data.email || '',
            countryDialCode: data.countryDialCode || '',
            phoneNumber: data.phoneNumber || '',
            gender: data.gender || '',
            age: data.age || '',
            noOfSpokenLangs: data.noOfSpokenLangs || '',
            engProficiency: data.engProficiency || '',
          }}
          enableReinitialize
          validationSchema={Yup.object({
            firstName: Yup.string().required(
              t('register.user.required.firstName')
            ),
            lastName: Yup.string().required(
              t('register.user.required.lastName')
            ),
            firstName_AR: Yup.string().required(
              t('register.user.required.firstName_AR')
            ),
            lastName_AR: Yup.string().required(
              t('register.user.required.lastName_AR')
            ),
            nationality: Yup.object().required(
              t('register.user.required.nationality')
            ),
            email: Yup.string()
              .required(t('register.user.required.email'))
              .email(t('register.user.required.invalidEmail')),
            countryDialCode: Yup.object().required(
              t('register.user.required.countryDialCode')
            ),
            phoneNumber: Yup.string()
              .required(t('register.user.required.phoneNumber'))
              .test({
                name: 'is-valid-phone',
                message: t('register.user.required.invalidPhone'),
                test: (value) => {
                  let valid = isNumber(value) ? false : true;
                  if (valid) {
                    valid =
                      Number(value) > 0 &&
                      value.length < 20 &&
                      value.length >= 1;
                  }
                  return valid;
                },
              }),
            gender: Yup.object().required(t('register.user.required.gender')),
            age: Yup.string()
              .required(t('register.user.required.age'))
              .test({
                name: 'validAge',
                message: t('register.user.required.invalidAge'),
                test: (value) => {
                  return Number(value) <= 300 && Number(value) >= 1
                    ? true
                    : false;
                },
              }),
            noOfSpokenLangs: Yup.string()
              .required(t('register.user.required.noOfSpokenLangs'))
              .test({
                name: 'validSpokenLangs',
                message: t('register.user.required.invalidNoOfSpokenLangs'),
                test: (value) => {
                  return Number(value) <= 10 && Number(value) >= 1
                    ? true
                    : false;
                },
              }),
            engProficiency: Yup.object().required(
              t('register.user.required.engProficiency')
            ),
          })}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <section className="content">
            <div className="container-fluid px-0">
              <div className="card">
                <div className="card-body">
                  <StyledH3 className="mb-3 mt-0 border-bottom-1">
                    {t('register.user.userDetails')}
                  </StyledH3>
                  <Row className="mb-2">
                    <Col xs={12} lg={12}>
                      <Input
                        type="text"
                        name="firstName"
                        id="FirstName"
                        label={t('register.user.firstName')}
                        placeholder=""
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} lg={12}>
                      <Input
                        type="text"
                        name="lastName"
                        id="LastName"
                        label={t('register.user.lastName')}
                        placeholder=""
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} lg={12}>
                      <Input
                        type="text"
                        name="firstName_AR"
                        id="FirstName_AR"
                        label={t('register.user.firstName_AR')}
                        placeholder=""
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} lg={12}>
                      <Input
                        type="text"
                        name="lastName_AR"
                        id="LastName_AR"
                        label={t('register.user.lastName_AR')}
                        placeholder=""
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} lg={12}>
                      <DropdownField
                        id="nationality"
                        name="nationality"
                        label={t('register.user.nationality')}
                        options={NATIONALITY}
                        placeholder=""
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} lg={6}>
                      <Input
                        type="email"
                        name="email"
                        id="Email"
                        label={t('register.user.email')}
                        placeholder=""
                        required
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <Row>
                        <Col xs={12} lg={5}>
                          <DropdownField
                            id="countryDialCode"
                            name="countryDialCode"
                            label={t('register.user.countryDialCode')}
                            options={COUNTRIES_DIAL_CODES}
                            placeholder=""
                            required
                            onChange={(val) => {
                              setDialCodeCountry(val.value.split(':').shift());
                            }}
                            description={dialCodeCountry}
                          />
                        </Col>
                        <Col xs={12} lg={7}>
                          <Input
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            label={t('register.user.phoneNumber')}
                            placeholder=""
                            required
                            description={t('register.user.phoneNumberDesc')}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} lg={6}>
                      <DropdownField
                        id="gender"
                        name="gender"
                        label={t('register.user.gender')}
                        options={GENDER_LIST}
                        placeholder=""
                        required
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <Input
                        type="number"
                        name="age"
                        id="age"
                        label={t('register.user.age')}
                        placeholder=""
                        required
                        min={20}
                        max={40}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} lg={6}>
                      <Input
                        type="number"
                        name="noOfSpokenLangs"
                        id="noOfSpokenLangs"
                        label={t('register.user.noOfSpokenLangs')}
                        placeholder=""
                        required
                        min={1}
                        max={10}
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <DropdownField
                        id="engProficiency"
                        name="engProficiency"
                        label={t('register.user.engProficiency')}
                        options={ENGLISH_PROFICIENCY}
                        placeholder=""
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="mx-2">
              <Row className="mt-4">
                <Col>
                  <BlueButton type="submit" className="px-5">
                    {t('register.educational.nextBtnLabel')}
                  </BlueButton>
                </Col>
              </Row>
            </div>
          </section>
        </Form>
      </div>
    </>
  );
};

export default UserRegister;
