import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {MainHeader} from '@components';
import styled from 'styled-components';
import MemberBenefits from '@app/assets/png/member-benefits.png';

const StyledQuestion = styled.div`
  font-weight: 500;
  color: #282c68;
  font-size: 1.2rem;
`;

const StyledAnswer = styled.div`
  font-size: 1rem;
  font-weight: normal;
`;

const StyledImage = styled.img`
  width: 60% !important;
  @media (max-width: 992px) {
    width: 100% !important;
  }
`;

const Faqs = () => {
  return (
    <Row noGutters className="mx-2 mx-lg-0">
      <Col xs={0} lg={2} className="d-none d-lg-block">
        {' '}
      </Col>
      <Col xs={12} lg={8}>
        <MainHeader page="faqs" />
        <div className="py-3">
          <StyledQuestion>Who is SELA for ?</StyledQuestion>
          <StyledAnswer className="mb-3">
            SELA is for young talented Saudis who are passionate about energy,
            who are quick learners, creative and ambitious. Candidates must also
            have strong communication skills and problem-solving skills, show
            leadership potential, can adapt, and must be a team.
          </StyledAnswer>
          <StyledQuestion>
            What are the benefits of becoming a SELA member?
          </StyledQuestion>
          <StyledAnswer className="mb-3">
            There are many benefits to becoming SELA members:
            <div>
              <StyledImage src={MemberBenefits} />
            </div>
          </StyledAnswer>

          <StyledQuestion>What is the application process?</StyledQuestion>
          <StyledAnswer className="mb-3">
            The application process has 4 steps:
            <ol>
              <li>Apply to SELA through the link above</li>
              <li>Review of application through SELA team</li>
              <li>
                Interview the candidates that have met the review criteria
              </li>
              <li>Send out the decision</li>
            </ol>
          </StyledAnswer>
          <StyledQuestion>
            What are the requirements for acceptance into SELA?
          </StyledQuestion>
          <StyledAnswer className="mb-3">
            Candidates shall meet the eligibility criteria and pass the
            interview.
          </StyledAnswer>
          <StyledQuestion>How long is SELA membership?</StyledQuestion>
          <StyledAnswer className="mb-3">
            The membership duration is 12 months, after which it can be renewed,
            additionally a new cohort of subscribers will enroll.
          </StyledAnswer>
          <StyledQuestion>
            What if I do not meet all the criteria?
          </StyledQuestion>
          <StyledAnswer className="mb-3">
            Applicants who fail to fully meet our eligibility criteria yet excel
            significantly in elements of the criteria will be reviewed by the
            recruitment committee for a potential interview. And admission into
            SELA.
          </StyledAnswer>
          <StyledQuestion>
            When will I receive feedback on application and potential review
            date?
          </StyledQuestion>
          <StyledAnswer className="mb-3">
            Once you have applied you should expect to receive the decision
            within 2-4 week.
          </StyledAnswer>
        </div>
      </Col>
      <Col xs={0} lg={2} className="d-none d-lg-block">
        {' '}
      </Col>
    </Row>
  );
};

export default Faqs;
