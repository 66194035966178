import React from 'react';
import {Form, Tooltip, OverlayTrigger, Button} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import {unset} from 'lodash';
import {StyledInput, StyledLabel, StyledRequiredLabel} from '@styled';

const Input = ({
  label,
  type,
  placeholder,
  description,
  required,
  id,
  field,
  meta,
  min,
  max,
  disabled,
  onChange,
  maxLength,
  width,
  rows,
  as: asType,
  tooltip = null,
  textAlignment,
}) => {
  const isInvalid = meta.error ? true : false;
  //   console.log("Props", meta, field);//todo: textarea touch event not catching properly

  const renderTooltip = (props) => (
    <Tooltip id={`button-tooltip-${tooltip.id}`} {...props}>
      {tooltip.text}
    </Tooltip>
  );

  const renderTooltipIcon = () => {
    return tooltip ? (
      <OverlayTrigger
        placement={tooltip.position || 'auto'}
        delay={{show: 250, hide: 400}}
        overlay={renderTooltip}
      >
        <Button
          size="sm"
          variant="secondary"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: '#000000',
            opacity: 0.5,
          }}
        >
          {tooltip.icon ? (
            tooltip.icon
          ) : (
            <i className="fa fa-question-circle" />
          )}
        </Button>
      </OverlayTrigger>
    ) : null;
  };

  return (
    <Form.Group controlId={id}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {label && required && <StyledRequiredLabel>*</StyledRequiredLabel>}
      {renderTooltipIcon()}
      <StyledInput
        required={required || false}
        type={type}
        as={asType}
        placeholder={placeholder}
        isInvalid={isInvalid}
        min={min}
        max={max}
        disabled={disabled}
        maxLength={maxLength}
        width={width}
        value={meta.value}
        {...field}
        onChange={onChange}
        className={
          asType === 'textarea'
            ? `form-control ${isInvalid ? 'is-invalid' : ''}`
            : undefined
        }
        rows={rows}
        style={{textAlign: textAlignment || undefined}}
      />
      {description && (
        <Form.Text className="text-muted">{description}</Form.Text>
      )}

      {isInvalid && (
        <Form.Control.Feedback type="invalid">
          {meta.error || ''}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

const InputController = (props) => {
  const [field, meta, helpers] = useField(props);
  const {errors, setErrors} = useFormikContext();

  const onChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    } else {
      field.onChange(e);
    }
    const tmpErrors = errors;
    unset(tmpErrors, field.name);
    setErrors(tmpErrors);
  };
  return () => Input({...props, field, meta, helpers, onChange});
};

const InputField = (props) => InputController(props)();
export default InputField;
