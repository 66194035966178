import {useState, useEffect} from 'react';
import StepperNav from './StepperNav';

const StepperNavController = ({startStep, ...props}) => {
  const {currentStep} = props;
  const [lastStep, setLastStep] = useState(0);

  useEffect(() => {
    setLastStep(currentStep);
  }, [currentStep]);

  return () => StepperNav({...props, lastStep});
};

export default (props) => StepperNavController(props)();
