/* eslint-disable indent */

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Col} from 'react-bootstrap';
import {BasicStepper, MainHeader, MainLoader} from '@components';
import axios from '@app/utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import UserRegister from './UserRegister';
import EducationalDetails from './EducationalDetails';
import OrganizationalDetails from './OrganizationalDetails';
import CompleteView from './CompleteView';
import ReviewSummary from './ReviewSummary';

function DataReducer(state, action) {
  switch (action.type) {
    case 'UPDATE': {
      return {...state, ...action.payload};
    }
    default:
      throw new Error();
  }
}
// nationality gender engProficiency educationLevel country university sector membershipInProfSocieties affToEnergySector expectedGraduationDate cvFile
const RegisterController = () => {
  const [t] = useTranslation();
  const [formData, dispatch] = React.useReducer(
    DataReducer,
    {}
    // {
    //   firstName: 'Steve',
    //   lastName: 'Daniels',
    //   firstName_AR: 'Steve',
    //   lastName_AR: 'Daniels',
    //   nationality: {label: 'Saudi', value: 'Saudi'},
    //   email: 'test-dev@example.com',
    //   countryDialCode: {label: '+966(SA)', value: 'Saudi Arabia:+966'},
    //   phoneNumber: '5000000000',
    //   gender: {label: 'Male', value: 'Male'},
    //   age: 21,
    //   noOfSpokenLangs: 1,
    //   engProficiency: {
    //     label: 'Intermediate',
    //     value: 'Intermediate',
    //   },
    //   studentOrProfessional: 'Student',
    //   educationLevel: {label: 'Masters', value: 'Masters'},
    //   major: 'Test',
    //   country: {label: 'United States', value: 'United States'},
    //   university: {
    //     label: 'University of Oxford',
    //     value: 'University of Oxford',
    //   },
    //   otherUniversity: '',
    //   sector: {label: 'Non-profit', value: 'Non-profit'},
    //   organization: 'Test',
    //   totalYearsOfExperience: 1,
    //   affToEnergySector: [],
    //   membershipInProfSocieties: ['Test'],
    //   gpa: 'Test',
    //   expectedGraduationDate: '09-02-2022',
    // }
  );
  const [currentStep, setStepperStep] = React.useState(0);
  const [isLoading, toggleLoading] = React.useState(false);

  React.useEffect(() => {
    const storedData = JSON.parse(
      sessionStorage.getItem('sela-app-form-data') || '{}'
    );
    dispatch({type: 'UPDATE', payload: storedData});
  }, []);

  React.useEffect(() => {
    // const {cvFile, ...restData} = formData;
    sessionStorage.setItem('sela-app-form-data', JSON.stringify(formData));
  }, [formData]);

  const stepsNav = React.useMemo(() => [
    {
      icon: <i className="fa fa-circle" style={{fontSize: '1.5rem'}} />,
      title: t('register.steps.userDetails'),
      caption: t('register.steps.userDetails'),
    },
    {
      icon: <i className="fa fa-circle" style={{fontSize: '1.5rem'}} />,
      title: t('register.steps.educationDetails'),
      caption: t('register.steps.educationDetails'),
    },
    {
      icon: <i className="fa fa-circle" style={{fontSize: '1.5rem'}} />,
      title: t('register.steps.organization'),
      caption: t('register.steps.organization'),
    },
    {
      icon: <i className="fa fa-circle" style={{fontSize: '1.5rem'}} />,
      title: t('register.steps.completeRegistration'),
      caption: t('register.steps.completeRegistration'),
    },
  ]);

  const handleGotoStep = (step, values) => {
    if (values) {
      dispatch({type: 'UPDATE', payload: values});
    }

    if (step > stepsNav.length) {
      setStepperStep(step);
    } else {
      setStepperStep(step);
    }
  };

  const handleComplete = () => {
    toggleLoading(true);

    const payloadFormData = new FormData();
    const {
      nationality,
      gender,
      engProficiency,
      countryDialCode,
      phoneNumber,
      educationLevel,
      country,
      university,
      sector,
      affToEnergySector,
      expectedGraduationDate,
      cvFile,
      membershipInProfSocieties,
      ...restData
    } = formData;
    Object.keys(restData).map((key) => {
      payloadFormData.append(key, restData[key]);
      return key;
    });
    const dialCode = countryDialCode.value.split(':').pop();
    payloadFormData.append('phoneNumber', `${dialCode}${phoneNumber}`);
    payloadFormData.append('nationality', nationality.value);
    payloadFormData.append('gender', gender.value);
    payloadFormData.append('engProficiency', engProficiency.value);
    payloadFormData.append('educationLevel', educationLevel.value);
    payloadFormData.append('country', country.value);
    payloadFormData.append('university', university.value);
    payloadFormData.append('sector', sector.value);
    payloadFormData.append('affToEnergySector', affToEnergySector.join(', '));
    payloadFormData.append(
      'expectedGraduationDate',
      moment(expectedGraduationDate).isValid()
        ? moment(expectedGraduationDate).format('DD-MM-YYYY')
        : ''
    );
    payloadFormData.append(
      'membershipInProfSocieties',
      membershipInProfSocieties.join(', ')
    );

    payloadFormData.append('cvFile', cvFile);

    axios
      .post(
        'https://prod-197.westeurope.logic.azure.com:443/workflows/e0ae418a4487425fb4316952f6b29e58/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=tGpZH1ye3kigpjt64xX6JV-_760lOCUTtXERf3xqBU4',
        payloadFormData
      )
      .then((result) => {
        if (result.data.success) {
          toast.success(result.data.message);
          sessionStorage.setItem('sela-app-form-data', '');
          handleGotoStep(4);
        } else {
          toast.error(
            result.data.message ||
              'Error while processing your application form'
          );
        }
        return result;
      })
      .catch(() => {
        toast.error('Error while processing your application form');
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  document.getElementById('root').classList = 'hold-transition registration';

  return (
    <div>
      {isLoading && (
        <MainLoader
          minHeight="100vh"
          spinnerColor="#282c68"
          background="rgba(0,0,0,0.2)"
        />
      )}
      <Row noGutters className="mx-2 mx-lg-0">
        <Col xs={0} lg={2} className="d-none d-lg-block">
          {' '}
        </Col>
        <Col xs={12} lg={8}>
          <MainHeader page="register" />
          <div className="py-3">
            <BasicStepper
              isNextClick={false}
              disabledSteps={[]}
              mainCaption=""
              currentStep={currentStep}
              setCurrentStep={() => {}}
              steps={stepsNav}
            />
            {currentStep === 0 && (
              <UserRegister data={formData} handleNext={handleGotoStep} />
            )}
            {currentStep === 1 && (
              <EducationalDetails data={formData} handleNext={handleGotoStep} />
            )}
            {currentStep === 2 && (
              <OrganizationalDetails
                data={formData}
                handleNext={handleGotoStep}
              />
            )}
            {currentStep === 3 && (
              <ReviewSummary
                data={formData}
                handleNext={handleGotoStep}
                handleComplete={handleComplete}
              />
            )}

            {currentStep === 4 && <CompleteView />}
          </div>
        </Col>
        <Col xs={0} lg={2} className="d-none d-lg-block">
          {' '}
        </Col>
      </Row>
    </div>
  );
};

export default RegisterController;
