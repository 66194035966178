/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import {get} from 'lodash';

const instance = axios.create();

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (typeof error.response === 'undefined') {
      return Promise.reject({
        error: 'Session Expired',
        status: 0,
        response: null,
      });
    }
    const status = get(error.response, 'status');
    return Promise.reject({
      error: get(error.response, 'message', 'Bad request'),
      status,
      response: error.response.data || error.response,
    });
  }
);

export default instance;
