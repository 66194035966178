import React from 'react';
import * as Yup from 'yup';
import 'yup-phone';
import {Form as FormRender} from 'formik';
import {Row, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Input, Form, DropdownField, MainLoader, RadioField} from '@components';
import {
  BlueButton,
  StyledH3,
  StyledLabel,
  StyledRequiredLabel,
  WhiteButton,
} from '@styled';
import {
  COUNTRIES_LIST,
  EDUCATION_LEVELS,
  UNIVERSITIES,
} from '@app/utils/constants';

const EducationalDetails = ({data, handleNext}) => {
  const [t] = useTranslation();
  const [isLoading, toggleLoading] = React.useState(false);
  const [showOtherUniv, toggleOtherUniv] = React.useState(false);
  const [selectedCountry, setCountry] = React.useState(data.country || null);

  const FILTERED_UNIV = React.useMemo(() => {
    return UNIVERSITIES.filter((item) => {
      const countryMatched =
        selectedCountry && item.country === selectedCountry.value
          ? true
          : false;
      if (countryMatched || !selectedCountry || item.country === 'empty') {
        return true;
      }
      return false;
    }).map((item) => ({label: item.name, value: item.name}));
  }, [selectedCountry]);

  React.useState(() => {
    if (data.university && data.university.value === 'Others') {
      toggleOtherUniv(true);
    } else {
      toggleOtherUniv(false);
    }
  }, [data]);

  const handleSubmit = (values) => {
    toggleLoading(true);
    setTimeout(() => {
      toggleLoading(false);
      handleNext(2, values);
    }, 500);
  };

  return (
    <>
      {isLoading && (
        <MainLoader
          minHeight="100vh"
          spinnerColor="#282c68"
          background="rgba(0,0,0,0)"
        />
      )}
      <div>
        <Form
          initialValues={{
            studentOrProfessional: data.studentOrProfessional || '',
            educationLevel: data.educationLevel || '',
            major: data.major || '',
            country: data.country || '',
            university: data.university || '',
            otherUniversity: data.otherUniversity || '',
          }}
          enableReinitialize
          validationSchema={Yup.object({
            studentOrProfessional: Yup.string().required(
              t('register.educational.required.studentOrProfessional')
            ),
            educationLevel: Yup.object().required(
              t('register.educational.required.educationLevel')
            ),
            major: Yup.string().required(
              t('register.educational.required.major')
            ),
            country: Yup.object().required(
              t('register.educational.required.country')
            ),
            university: Yup.object().required(
              t('register.educational.required.university')
            ),
            otherUniversity: Yup.string().when('university', {
              is: (cc) => cc && cc.value === 'Others',
              then: Yup.string().required(
                t('register.educational.required.otherUniversity')
              ),
            }),
          })}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({values, setFieldValue}) => {
            return (
              <FormRender noValidate className="reactForm">
                <section className="content">
                  <div className="container-fluid  px-0">
                    <div className="card">
                      <div className="card-body">
                        <StyledH3 className="mb-3 mt-0 border-bottom-1">
                          {t('register.educational.educationalDetails')}
                        </StyledH3>
                        <Row className="mb-2">
                          <Col xs={12} lg={12}>
                            <StyledLabel>
                              {t('register.educational.studentOrProfessional')}
                            </StyledLabel>
                            <StyledRequiredLabel>*</StyledRequiredLabel>
                            <div className="d-flex ml-3">
                              <RadioField
                                id="isStudent"
                                name="studentOrProfessional"
                                label={t('register.educational.isStudentLabel')}
                                placeholder=""
                                className="mr-1"
                                required
                                value="Student"
                              />
                              <RadioField
                                id="isProfessional"
                                name="studentOrProfessional"
                                label={t(
                                  'register.educational.isProfessionalLabel'
                                )}
                                placeholder=""
                                className="ml-1"
                                value="Professional"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs={12} lg={12}>
                            <DropdownField
                              id="educationLevel"
                              name="educationLevel"
                              label={t('register.educational.educationLevel')}
                              options={EDUCATION_LEVELS}
                              placeholder=""
                              required
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs={12} lg={12}>
                            <Input
                              type="text"
                              name="major"
                              id="major"
                              label={t('register.educational.major')}
                              placeholder=""
                              required
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs={12} lg={12}>
                            <DropdownField
                              id="country"
                              name="country"
                              label={t(
                                `register.educational.country${values.studentOrProfessional}`
                              )}
                              options={COUNTRIES_LIST}
                              placeholder=""
                              required
                              onChange={(val) => {
                                setCountry(val);
                                setFieldValue('university', '');
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs={12} lg={12}>
                            <DropdownField
                              id="university"
                              name="university"
                              label={t('register.educational.university')}
                              options={FILTERED_UNIV}
                              placeholder={t('register.educational.university')}
                              required
                              onChange={(val) => {
                                toggleOtherUniv(
                                  val.value === 'Others' ? true : false
                                );
                              }}
                              description={t(
                                'register.educational.universityHelp'
                              )}
                            />
                          </Col>
                        </Row>
                        <Row
                          className={`mb-2 ${!showOtherUniv ? 'd-none' : ''}`}
                        >
                          <Col xs={12} lg={12}>
                            <Input
                              type="text"
                              name="otherUniversity"
                              id="otherUniversity"
                              label={t('register.educational.otherUniversity')}
                              placeholder=""
                              required
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="mx-2">
                    <Row className="mt-4">
                      <Col>
                        <WhiteButton
                          type="button"
                          className="px-5 mr-1"
                          onClick={() => handleNext(0, null)}
                        >
                          {t('register.educational.previousBtnLabel')}
                        </WhiteButton>
                        <BlueButton type="submit" className="px-5 ml-1">
                          {t('register.educational.nextBtnLabel')}
                        </BlueButton>
                      </Col>
                    </Row>
                  </div>
                </section>
              </FormRender>
            );
          }}
        </Form>
      </div>
    </>
  );
};

export default EducationalDetails;
