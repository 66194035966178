import React from 'react';
import {Form, Formik} from 'formik';

const CustomForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  children,
  ...rest
}) => {
  const isFunction = typeof children === 'function';
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      {...rest}
    >
      {isFunction ? (
        children
      ) : (
        <Form noValidate className="reactForm">
          {children}
        </Form>
      )}
    </Formik>
  );
};

export default CustomForm;
