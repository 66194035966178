import React from 'react';
import {
  Form,
  Tooltip,
  OverlayTrigger,
  Button,
  InputGroup,
} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import {unset} from 'lodash';
import DateTime from 'react-datetime';
import {StyledInput, StyledLabel, StyledRequiredLabel} from '@styled';

const DatePicker = ({
  label,
  placeholder,
  description,
  required,
  id,
  field,
  meta,
  disabled,
  onChange,
  onBlur,
  tooltip = null,
  dateFormat = true,
  timeFormat = true,
}) => {
  const isInvalid = meta.error ? true : false;
  //   console.log("Props", meta, field);//todo: textarea touch event not catching properly

  const renderTooltip = (props) => (
    <Tooltip id={`button-tooltip-${tooltip.id}`} {...props}>
      {tooltip.text}
    </Tooltip>
  );

  const renderTooltipIcon = () => {
    return tooltip ? (
      <OverlayTrigger
        placement={tooltip.position || 'auto'}
        delay={{show: 250, hide: 400}}
        overlay={renderTooltip}
      >
        <Button
          size="sm"
          variant="secondary"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: '#000000',
            opacity: 0.5,
          }}
        >
          {tooltip.icon ? (
            tooltip.icon
          ) : (
            <i className="fa fa-question-circle" />
          )}
        </Button>
      </OverlayTrigger>
    ) : null;
  };

  const renderInputControl = (dtProps, openCalendar) => {
    return (
      <InputGroup>
        <StyledInput {...dtProps} />
        <InputGroup.Append>
          <Button
            variant="outline-secondary"
            onClick={openCalendar}
            style={{
              color: '#3d4295',
              borderColor: '#acaed1',
              backgroundColor: '#d0d1e5',
            }}
          >
            <i className="fa fa-calendar" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    );
  };

  return (
    <Form.Group controlId={id}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {label && required && <StyledRequiredLabel>*</StyledRequiredLabel>}
      {renderTooltipIcon()}
      <DateTime
        renderInput={renderInputControl}
        value={meta.value}
        placeholder={placeholder}
        disabled={disabled}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        {...field}
        closeOnSelect
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        className={isInvalid ? 'rdt is-invalid' : 'rdt'}
      />
      {description && (
        <Form.Text className="text-muted">{description}</Form.Text>
      )}

      {isInvalid && (
        <Form.Control.Feedback type="invalid">
          {meta.error || ''}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

const DatePickerController = (props) => {
  const [field, meta, helpers] = useField(props);
  const {errors, setErrors} = useFormikContext();

  const onChange = (value) => {
    helpers.setValue(value);
    const tmpErrors = errors;
    unset(tmpErrors, field.name);
    setErrors(tmpErrors);
  };

  const onBlur = () => {
    setTimeout(() => {
      helpers.setTouched(true);
    }, 1);
  };
  return () => DatePicker({...props, field, meta, helpers, onChange, onBlur});
};

const DatePickerField = (props) => DatePickerController(props)();
export default DatePickerField;
