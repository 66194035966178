import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

function ScrollToTop({history}) {
  useEffect(() => {
    const historyEvent = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      historyEvent();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
