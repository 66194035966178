import React from 'react';
import {Form, Tooltip, OverlayTrigger, Button} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import {unset} from 'lodash';
import {components} from 'react-select';
import {
  StyledSelect2,
  StyledSelect2Async,
  StyledLabel,
  StyledRequiredLabel,
} from '@styled';

const CustomMenuWithFooter = ({menuHeader, menuFooter, maxItems, ...props}) => {
  const optionsLength = props.options.length;
  const optionSelectedLength = props.getValue().length || 0;
  let showHeaderFooter = true;
  if (maxItems > 0 && optionSelectedLength >= maxItems) {
    showHeaderFooter = false;
  }
  return (
    <>
      {menuHeader && showHeaderFooter && (
        <div style={{padding: '8px 12px', backgroundColor: '#fff'}}>
          {menuHeader.replace(/#itemsLength#/gi, optionsLength)}
        </div>
      )}
      <components.Menu {...props}>
        {maxItems === 0 || optionSelectedLength < maxItems
          ? props.children
          : null}
        {/* (
          <div style={{ margin: 15 }}>
            Only {maxItems} item allowed to select
          </div>
        ) */}
      </components.Menu>
      {menuFooter && showHeaderFooter && (
        <div style={{padding: '8px 12px', backgroundColor: '#fff'}}>
          {menuFooter.replace(/#itemsLength#/gi, optionsLength)}
        </div>
      )}
    </>
  );
};

const Dropdown = ({
  label,
  placeholder,
  description,
  required,
  id,
  field,
  meta,
  disabled,
  onChange,
  onBlur,
  options,
  isMulti,
  getOptionValue,
  getOptionLabel,
  formatOptionLabel,
  menuHeader,
  menuFooter,
  maxItems = 0,
  asyncHandler = null,
  tooltip = null,
  menuPortalTarget = null,
}) => {
  const isInvalid = meta.error ? true : false;
  //   console.log("Props", meta, field);//todo: textarea touch event not catching properly

  const getCustomMenu = () => {
    if (menuHeader || menuFooter || maxItems > 0) {
      return {
        Menu: (props) =>
          CustomMenuWithFooter({...props, menuHeader, menuFooter, maxItems}),
      };
    }
    return undefined;
  };

  const SelectComponent = asyncHandler ? StyledSelect2Async : StyledSelect2;

  const renderTooltip = (props) => (
    <Tooltip id={`button-tooltip-${tooltip.id}`} {...props}>
      {tooltip.text}
    </Tooltip>
  );

  const renderTooltipIcon = () => {
    return tooltip ? (
      <OverlayTrigger
        placement={tooltip.position || 'auto'}
        delay={{show: 250, hide: 400}}
        overlay={renderTooltip}
      >
        <Button
          size="sm"
          variant="secondary"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: '#000000',
            opacity: 0.5,
          }}
        >
          {tooltip.icon ? (
            tooltip.icon
          ) : (
            <i className="fa fa-question-circle" />
          )}
        </Button>
      </OverlayTrigger>
    ) : null;
  };

  return (
    <Form.Group controlId={id}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {label && required && <StyledRequiredLabel>*</StyledRequiredLabel>}
      {renderTooltipIcon()}
      <SelectComponent
        isMulti={isMulti}
        classNamePrefix="react-select"
        className={isInvalid ? 'is-invalid' : undefined}
        options={options}
        defaultValue={meta.initialValue}
        menuPlacement="auto"
        menuPortalTarget={
          menuPortalTarget && menuPortalTarget === 'parent'
            ? undefined
            : document.body
        }
        menuPosition="absolute"
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        defaultOptions={asyncHandler ? true : undefined}
        loadOptions={asyncHandler || undefined}
        {...field}
        value={
          meta.value
          // isMulti
          //   ? meta.value
          //   : options.find((d) => d.value === meta.value) || ""
        }
        onChange={onChange}
        onBlur={onBlur}
        styles={{
          menu: (provided) => ({
            ...provided,
            // backgroundColor: '#fff',
            // borderColor: '#dedecd',
            zIndex: 1060,
          }),
          option: (styles) => {
            return {
              ...styles,
              ':hover': {
                ...styles[':hover'],
                color: '#000',
                fontWeight: 600,
                // backgroundColor: '#DFDFDF',
              },
            };
          },
        }}
        components={getCustomMenu()}
      />

      {description && (
        <Form.Text className="text-muted">{description}</Form.Text>
      )}

      {isInvalid && (
        <Form.Control.Feedback type="invalid">
          {meta.error || ''}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

const DropdownController = (props) => {
  const [field, meta, helpers] = useField(props);
  const {errors, setErrors} = useFormikContext();

  const onChange = (data) => {
    const val = props.isMulti ? data || [] : data;
    helpers.setValue(val);

    const tmpErrors = errors;
    unset(tmpErrors, field.name);
    setErrors(tmpErrors);
    if (props.onChange) props.onChange(val);
  };

  const onBlur = () => {
    helpers.setTouched(true);
  };
  return () => Dropdown({...props, field, meta, helpers, onChange, onBlur});
};

const DropdownField = (props) => DropdownController(props)();
export default DropdownField;
