import styled from 'styled-components';

export const StyledStepperNav = styled.div`
  margin-bottom: 16px;
  position: relative;
  @media (max-width: 992px) {
    margin-top: 16px;
  }
  .line {
    display: block;
    height: 2px;
    background: #999dd9;
    width: 100%;
    position: absolute;
    top: 16px;
  }

  .line-after {
    display: block;
    height: 2px;
    background-color: #282c68;
    position: absolute;
    top: 16px;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      flex-flow: column;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      position: relative;
      max-width: 32px;
      white-space: nowrap;
      text-align: center;

      button {
        border: none;
        padding: 0;
        margin: 0;
        background: none;
        color: inherit;
      }

      .stepItem {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #999dd9;
        box-sizing: border-box;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background-color: #fff;

        @media (min-width: 480px) {
          width: 32px;
          height: 32px;
        }
        svg,
        path {
          fill: #bbb !important;
        }
      }

      .step-title {
        font-weight: 300;
      }

      &.nextStep {
        color: #999dd9;
        .stepItem {
          cursor: pointer;
          border-color: #999dd9;
          background-color: #fff;

          svg,
          path {
            fill: #999dd9 !important;
          }
        }
      }

      &.active {
        color: #282c68;
        .stepItem {
          cursor: pointer;
          border-color: #282c68;

          svg,
          path {
            fill: #282c68 !important;
          }
        }
        .step-title {
          font-weight: 400;
        }
      }
    }
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: inherit;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 992px) {
    width: 80vw;
  }
`;
