import React from 'react';
import {StyledStepperNav, Wrapper} from './StepperNav.style';

const StepperNav = ({
  currentStep,
  setCurrentStep,
  steps,
  lastStep,
  disabledSteps = [],
}) => {
  const stepLineWidth = 100 / (steps.length - 1);
  const stepDec =
    currentStep >= steps.length - 1 ? steps.length - 1 : currentStep + 1;
  return (
    <Wrapper>
      <StyledStepperNav stepsCount={steps.length}>
        <div className="line" />
        <div
          style={{width: `${stepDec * stepLineWidth}%`}}
          className="line-after"
        />
        <ul>
          {steps.map((item, index) => {
            const moveIndex = disabledSteps.includes(index) ? index + 1 : index;
            return (
              <li
                key={`stepper-step-${index + 1}`}
                className={`${lastStep > index && 'active'} ${
                  currentStep !== index && 'nextStep'
                } ${currentStep === index && 'currentStep active'}`}
              >
                <button type="button" onClick={() => setCurrentStep(moveIndex)}>
                  <span className="stepItem">{item.icon}</span>
                </button>
                <div className="step-title text-wrap">
                  {/* {currentStep + 1 === index ? 'Next Step' : item.title} */}
                  {item.title}
                </div>
              </li>
            );
          })}
        </ul>
      </StyledStepperNav>
    </Wrapper>
  );
};

export default StepperNav;
