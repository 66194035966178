import React from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import {BlueButton, StyledH1, StyledH2} from '@styled';
import {ReactComponent as EyeIcon} from '@app/assets/svg/eye.svg';
import {ReactComponent as BullsEyeIcon} from '@app/assets/svg/bullseye.svg';
import {ReactComponent as ConnectingIcon} from '@app/assets/svg/connecting.svg';
import {ReactComponent as UpArrowIcon} from '@app/assets/svg/up-arrow.svg';
import {ReactComponent as BulbIcon} from '@app/assets/svg/lightbulb.svg';
import {ReactComponent as GroupPersonIcon} from '@app/assets/svg/group.svg';
import {useHistory} from 'react-router-dom';
import {MainHeader} from '@components';

const StyledCard = styled.div`
  min-height: 300px;
  height: 100%;
  width: 300px;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  & .mv-title {
    font-size: 2rem;
    text-transform: uppercase;
    color: #282c68;
    font-style: italic;
    position: relative;
    & .mv-svg-icon {
      width: 64px;
      height: auto;
      position: absolute;
      right: -46px;
      top: -46px;
      fill: #4a593a;
    }
  }
  & .mv-desc {
    font-size: 1.3rem;
  }
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    ${'' /* background-color: #ccb916; */}
    background-image:linear-gradient(45deg, #ccb916, #76af2e);
    opacity: 0.5;
  }
`;

const StyledHr = styled.hr`
  border-top-width: 2px;
  border-top-color: #4a573a;
`;

const StyledCircleIcon = styled.div`
  width: 64px;
  height: 64px;
  padding: 5px;
  border-radius: 50%;
  position: relative;
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: darkgreen;
    opacity: ${({opacity}) => (opacity ? opacity : '1')};
    border-radius: 50%;
  }
  & svg {
    opacity: 0.5;
  }
`;

const Home = () => {
  const history = useHistory();
  const [t] = useTranslation();

  document.getElementById('root').classList = 'hold-transition registration';

  return (
    <Row noGutters className="mx-2 mx-lg-0">
      <Col xs={0} lg={2} className="d-none d-lg-block">
        {' '}
      </Col>
      <Col xs={12} lg={8}>
        <MainHeader page="home" />
        <div className="py-3">
          <StyledH1
            style={{color: '#282C68', fontWeight: 500}}
            className="mt-2 text-center"
          >
            {t('home.contentTitle')}
          </StyledH1>
          <Row noGutters className="mt-5">
            <Col xs={12} lg={6} className="mb-5 mb-lg-0">
              <StyledCard className="elevation-1 mx-auto">
                <div className="mv-title mb-3">
                  {t('home.vision')} <EyeIcon className="mv-svg-icon" />
                </div>
                <div className="mv-desc ml-3">{t('home.visionDesc')}</div>
              </StyledCard>
            </Col>
            <Col xs={12} lg={6} className="mb-5 mb-lg-0">
              <StyledCard className="elevation-1 mx-auto">
                <div className="mv-title mb-3">
                  {t('home.mission')} <BullsEyeIcon className="mv-svg-icon" />
                </div>
                <div className="mv-desc ml-3">{t('home.missionDesc')}</div>
              </StyledCard>
            </Col>
          </Row>
          <Row noGutters className="mt-5">
            <Col xs={12} lg={12}>
              <StyledHr className="elevation-1" />
            </Col>
          </Row>
          <StyledH2
            style={{color: '#282C68', fontWeight: 500}}
            className="mt-2 text-center"
          >
            {t('home.contentTitle1')}
          </StyledH2>
          <Row noGutters className="my-5">
            <Col xs={12} lg={3} className="mb-5 mb-lg-0 mx-2 mx-lg-0">
              <StyledCircleIcon
                className="mx-auto mb-3 elevation-2"
                opacity="0.5"
              >
                <ConnectingIcon />
              </StyledCircleIcon>
              <StyledH2 className="text-center">
                {t('home.connectingIndustryPeers')}
              </StyledH2>
              <div>{t('home.connectingIndustryPeersDesc')}</div>
            </Col>
            <Col xs={12} lg={3} className="mb-5 mb-lg-0 mx-2 mx-lg-0">
              <StyledCircleIcon
                className="mx-auto mb-3 elevation-2"
                opacity="0.050"
              >
                <UpArrowIcon />
              </StyledCircleIcon>
              <StyledH2 className="text-center">
                {t('home.ecoSystemAlignment')}
              </StyledH2>
              <div>{t('home.ecoSystemAlignmentDesc')}</div>
            </Col>
            <Col xs={12} lg={3} className="mb-5 mb-lg-0 mx-2 mx-lg-0">
              <StyledCircleIcon
                className="mx-auto mb-3 elevation-2"
                opacity="0.8"
              >
                <BulbIcon />
              </StyledCircleIcon>
              <StyledH2 className="text-center">
                {t('home.knowledgeSharing')}
              </StyledH2>
              <div>{t('home.knowledgeSharingDesc')}</div>
            </Col>
            <Col xs={12} lg={3} className="mb-5 mb-lg-0 mx-2 mx-lg-0">
              <StyledCircleIcon
                className="mx-auto mb-3 elevation-2"
                opacity="0.15"
              >
                <GroupPersonIcon />
              </StyledCircleIcon>
              <StyledH2 className="text-center">
                {t('home.futureLeaders')}
              </StyledH2>
              <div>{t('home.futureLeadersDesc')}</div>
            </Col>
          </Row>
          <Row noGutters className="mt-5">
            <Col xs={12} lg={12}>
              <StyledHr className="elevation-1" />
            </Col>
          </Row>
          <StyledH2
            style={{color: '#282C68', fontWeight: 500}}
            className="mt-2 mx-2 mx-lg-0"
          >
            {t('home.contentTitle2')}
          </StyledH2>
          <div className="text-center my-5">
            <BlueButton
              type="button"
              // variant="success"
              className="px-5 py-2"
              style={{fontWeight: 600}}
              onClick={() => {
                history.push('/register');
              }}
            >
              {t('home.applyHere')}
            </BlueButton>
          </div>
        </div>
      </Col>
      <Col xs={0} lg={2} className="d-none d-lg-block">
        {' '}
      </Col>
    </Row>
  );
};

export default Home;
